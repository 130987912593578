import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import api from "../../../utils/api";
import useAuth from "../../../hooks/useAuth";

export const ChangeApiPage = () => {
  const [mail, setMail] = useState("/login");
  const { loginAlt } = useAuth();

  const [body, setBody] = useState({
    access_token: "",
    change_mode: "true",
    mail: mail,
  });
  const [response, setResponse] = useState("");

  const handleSendRequest = async () => {
    console.log("click");
    try {
      const res = await api
        .post("/login", { change_mode: "true", mail: mail })
        .then(async (response) => {
          console.log("response =>", response);
          await loginAlt({ access_token: response.data.token });
        })
        .catch((error) => {
          console.error("error =>", error);
        });
      console.log("click", res);
    } catch (error) {
      console.log("error ", error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        API Request
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="URL"
            variant="outlined"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendRequest}
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
