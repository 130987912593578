import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { ImageUploadModal } from "../../Modals/ImageUploadImage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export const EventsTable = ({ events, update = () => {}, isEdit = false }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [visibleItems, setVisibleItems] = useState(3);
  const { showFlashMessage } = useFlashMessage();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [uploadFormModalOpen, setUploadFormModalOpen] = useState(false);

  const tableContainerRef = useRef(null); // Referência ao contêiner da tabela

  const handleDelete = useCallback(
    async (id) => {
      const confirmation = window.confirm(
        "Deseja realmente excluir este evento?"
      );

      if (!confirmation) {
        return;
      }

      try {
        await api
          .delete(`/admin/event/${id}`, { data: { id } })
          .then(() => {
            showFlashMessage("Exclusão bem-sucedida!", "success");
            update();
          })
          .catch((error) => {
            console.error("Erro ao excluir", error);
            showFlashMessage("Falha ao excluir!", "error");
          });
      } catch (error) {}
    },
    [showFlashMessage, update]
  );

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
    scrollToTop(); // Rolagem para o topo ao carregar mais itens
  };

  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const downloadFile = async (fileUrl) => {
    try {
      const headResponse = await fetch(
        `${process.env.REACT_APP_API}uploads/evento/${fileUrl}`,
        {
          method: "HEAD",
        }
      );

      const contentType = headResponse.headers.get("content-type");

      const fileResponse = await fetch(
        `${process.env.REACT_APP_API}uploads/evento/${fileUrl}`
      );
      const blob = await fileResponse.blob();

      const blobWithContentType = new Blob([blob], { type: contentType });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blobWithContentType);
      link.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao fazer o download do arquivo:", error);
    }
  };

  const ExpandedComponent = ({ data }) => {
    const handleDeleteFile = async () => {
      try {
        await api
          .delete(`/admin/event/file/${data?.event?.id}`)
          .then(() => {
            showFlashMessage("Exclusão bem-sucedida!", "success");
            update();
          })
          .catch((error) => {
            console.error("Erro ao excluir", error);
            showFlashMessage("Falha ao excluir!", "error");
          });
      } catch (error) {}
    };

    return (
      <Box sx={{ marginTop: "2rem", marginLeft: "2rem" }}>
        <Typography
          dangerouslySetInnerHTML={{ __html: data?.event?.description }}
        />
        {data?.event?.EventsFiles?.length > 0 && (
          <TableContainer component={Paper} sx={{ margin: "0.5rem" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Documento</TableCell>
                  <TableCell>Download</TableCell>
                  {isEdit && <TableCell>Ações</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.event?.EventsFiles?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell>{`${data?.event?.title}(${
                      index + 1
                    })`}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="Baixar"
                        onClick={() => downloadFile(item?.file)}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </TableCell>
                    {isEdit && (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ margin: "0.2rem" }}
                        >
                          Editar
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ margin: "0.2rem" }}
                          onClick={handleDeleteFile}
                        >
                          Excluir
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isEdit && (
          <Button
            onClick={() => handleOpenUploadForm(data?.event?.id)}
            variant="contained"
            color="primary"
            sx={{ margin: "1rem" }}
          >
            Cadastrar documento
          </Button>
        )}
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Título",
        selector: (row) => row?.event?.title,
        sortable: true,
      },
      {
        name: "Orgão",
        selector: (row) => row?.event?.agency,
        sortable: true,
      },
      {
        name: "Descrição",
        selector: (row) => (
          <Typography
            dangerouslySetInnerHTML={{
              __html: row?.event?.description.slice(0, 200),
            }}
          />
        ),
        sortable: true,
      },
      {
        name: "Data",
        selector: (row) =>
          new Date(row?.event?.date)
            .toLocaleDateString("pt-BR")
            .split("/")
            .join("/"),
        sortable: true,
      },
      isEdit && {
        name: "Ações",
        cell: (row) => (
          <Button
            onClick={() => handleDelete(row?.event?.id)}
            variant="contained"
            color="error"
          >
            Deletar
          </Button>
        ),
      },
    ],
    [handleDelete, isEdit]
  );

  useEffect(() => {
    setExpandedRows([]);
  }, [events]);

  const handleOpenUploadForm = (id) => {
    setUploadFormModalOpen(true);
    setSelectedRowId(id);
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row?.id);
    setExpandedRows((prevExpandedRows) =>
      isRowExpanded
        ? prevExpandedRows.filter((id) => id !== row?.id)
        : [...prevExpandedRows, row?.id]
    );
  };

  return (
    <Box>
      <Paper elevation={3} sx={{ borderRadius: "20px", paddingBottom: "5rem" }}>
        <DataTable
          columns={columns}
          data={events.slice(0, visibleItems)}
          expandableRows
          expandableRowExpanded={(row) => expandedRows.includes(row?.event?.id)}
          onRowClicked={handleRowClick}
          expandableRowsComponent={ExpandedComponent}
        />
      </Paper>

      {events?.length > visibleItems && (
        <Box
          sx={{ display: "flex", justifyContent: "center", margin: "0.5rem" }}
        >
          <Button variant="contained" onClick={handleLoadMore}>
            Carregar mais
          </Button>
        </Box>
      )}
      {isEdit && (
        <ImageUploadModal
          isOpen={uploadFormModalOpen}
          onRequestClose={() => setUploadFormModalOpen(false)}
          header="Envie o comprovante de pagamento"
          folder="evento"
          paymentId={selectedRowId}
        />
      )}
    </Box>
  );
};
