// utils/mapPaymentsData.js
export const mapPaymentsData = (dashboardData) => {
    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    return months.map((month, index) => {
        const paymentData = dashboardData.find((data) => {
            const [year, monthIndex] = data.month.split("-");
            return parseInt(monthIndex, 10) === index + 1;
        });

        return {
            month,
            totalPayments: paymentData ? paymentData.totalPayments : 0,
            notConfirmedPayments: paymentData ? paymentData.notConfirmedPayments : 0,
        };
    });
};
