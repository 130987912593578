import { Box, Grid, Paper } from "@mui/material";

export const DefaultContainer = ({ children }) => {
  return (
    <Box
      className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      sx={{
        minHeight: "65vh",
        maxHeight: "65vh",
        // margin: "2rem",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};
