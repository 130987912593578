import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Modal,
  Box,
} from "@mui/material";
import api from "../../utils/api";
import { ImageUploadModal } from "../../components/Modals/ImageUploadImage";
import { ShowFileButton } from "../../components/Buttons/ShowFileButton";
import { ImageVisualize } from "../../components/Visualize/ImageVisualize";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const Payments = () => {
  const [expandedMonth, setExpandedMonth] = useState(null); // Controla o mês selecionado
  const [payments, setPayments] = useState([]);
  const [uploadFormModalOpen, setUploadFormModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(""); // Mês selecionado para envio
  const [viewFileModalOpen, setViewFileModalOpen] = useState(false); // Modal para visualizar o arquivo
  const [selectedFileUrl, setSelectedFileUrl] = useState(""); // URL do arquivo selecionado
  const [selectedUpFolder, setSelectedUpFolder] = useState("newPayment");
  const fetchPaymentData = async () => {
    try {
      const response = await api.get("/auth/payments");
      setPayments(response?.data?.payments);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async () => {
    try {
      const response = await api.get("/auth/payments");
      setPayments(response?.data?.payments);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getPaymentsByMonth = (month) => {
    return payments.filter((payment) => {
      const dueMonth = new Date(payment.dueDate).getMonth();
      return months[dueMonth] === month;
    });
  };
  const handleViewFile = (fileUrl, folder = "newPayment") => {
    setSelectedFileUrl(fileUrl);
    setViewFileModalOpen(true);
    setSelectedUpFolder(folder);
  };

  const handleSendDocument = (id, month) => {
    setSelectedRowId(id);
    setSelectedMonth(month);
    setUploadFormModalOpen(true);
  };

  const handleModalClose = () => {
    setUploadFormModalOpen(false);
    fetchData();
  };
  const handleViewFileModalClose = () => {
    setViewFileModalOpen(false);
    setSelectedFileUrl("");
  };

  return (
    <Box sx={{ bgcolor: "background.paper", minHeight: "100vh", p: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Lista de cobranças por mês
      </Typography>

      <Grid container spacing={3}>
        {/* Layout responsivo para distribuir os meses em colunas */}
        {months.map((month, index) => (
          <Grid
            item
            xs={12}
            sm={6} // 2 colunas no tablet
            md={4} // 3 colunas no desktop
            lg={3}
            key={index}
          >
            <Card variant="outlined" sx={{ borderRadius: "1rem" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Button
                  fullWidth
                  onClick={() => setExpandedMonth(month)}
                  variant="text"
                  sx={{ fontWeight: "bold", mb: 2 }}
                >
                  {month}
                </Button>
                {expandedMonth === month && (
                  <>
                    {getPaymentsByMonth(month)?.length > 0 ? (
                      getPaymentsByMonth(month).map((payment) => {
                        const imageExtension = payment?.imageUrl
                          ?.split(".")
                          ?.pop()
                          ?.toLowerCase();

                        return (
                          <Box key={payment.id}>
                            <Typography variant="body1">
                              ID do Pagamento: {payment.id}
                            </Typography>
                            <Typography variant="body1">
                              Valor:{" "}
                              {payment.amount.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </Typography>
                            <Typography variant="body1">
                              Comprovante:{" "}
                              {payment?.imageUrl ? "Enviado" : "Não enviado"}
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleSendDocument(payment.id, month)
                                }
                              >
                                Enviar comprovante
                              </Button>
                            </Box>
                            {payment?.imageUrl && (
                              <ShowFileButton
                                fileType={imageExtension}
                                onClick={() =>
                                  handleViewFile(payment.imageUrl, "pagamento")
                                }
                              >
                                {payment.imageUrl}
                              </ShowFileButton>
                            )}
                            {payment?.user?.Profile[0]?.UploadedFiles?.length &&
                              payment?.user?.Profile[0]?.UploadedFiles?.map(
                                (file) => {
                                  return (
                                    <ShowFileButton
                                      fileType={imageExtension}
                                      onClick={() =>
                                        handleViewFile(
                                          file.filename,
                                          "newPayment"
                                        )
                                      }
                                    >
                                      {JSON.stringify(file.filename)}
                                    </ShowFileButton>
                                  );
                                }
                              )}
                          </Box>
                        );
                      })
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        Nenhuma cobrança lançada para esse mês.
                      </Typography>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal para upload de comprovante */}
      <ImageUploadModal
        isOpen={uploadFormModalOpen}
        onRequestClose={handleModalClose}
        header={`Comp. de pagamento - ${selectedMonth}`}
        folder="newPayment"
        paymentId={selectedRowId}
        month={selectedMonth}
      />

      <Modal
        open={viewFileModalOpen}
        onClose={handleViewFileModalClose}
        aria-labelledby="file-modal-title"
        aria-describedby="file-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Typography id="file-modal-title" variant="h6" component="h2">
            Visualização de Arquivo
          </Typography>
          {selectedFileUrl && (
            <div>
              {selectedFileUrl.endsWith(".pdf") ? (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              ) : (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              )}
            </div>
          )}
        </Box>
      </Modal>
    </Box>
  );
};
