import React, { useState } from "react";
import { FiUploadCloud } from "react-icons/fi";
import {
  Box,
  Button,
  Typography,
  Paper,
  IconButton,
  Input,
} from "@mui/material";
import api from "../../utils/api";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";

export const ImageUploadForm = ({
  header = "Upload de Arquivo",
  folder = "default",
  paymentId = null,
  userId = null,
  month = null,
  update = () => {},
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState("");
  const { showFlashMessage } = useFlashMessage();

  selectedFolder !== folder && setSelectedFolder(folder);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileUpload = async () => {
    try {
      console.log("userId", userId);
      const formData = new FormData();
      formData.append("folder", selectedFolder);
      formData.append("file", selectedFile);
      paymentId && formData.append("paymentId", paymentId);
      userId && formData.append("userId", userId);
      month && formData.append("month", month);

      await api.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showFlashMessage("Arquivo enviado com sucesso!", "success");
      update();
    } catch (error) {
      if (error?.response?.data?.message) {
        showFlashMessage(error?.response?.data?.message, "error");
      } else {
        console.error("Falha ao enviar arquivo:", error);
        showFlashMessage("Falha ao enviar arquivo.", "error");
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: "auto",
        mt: 5,
        p: 3,
      }}
    >
      <Typography variant="h4" gutterBottom>
        {header}
      </Typography>

      <Paper
        elevation={2}
        sx={{
          p: 3,
          border: "2px dashed",
          borderColor: "grey.400",
          textAlign: "center",
          mb: 3,
          cursor: "pointer",
        }}
        onClick={triggerFileInput}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {selectedFile ? (
          <Typography variant="body1" color="textSecondary">
            Arquivo Selecionado: {selectedFile.name}
          </Typography>
        ) : (
          <Box>
            <IconButton>
              <FiUploadCloud size={40} />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              Arraste e solte um arquivo aqui ou clique para selecionar.
            </Typography>
          </Box>
        )}
        <Input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          sx={{ display: "none" }}
        />
      </Paper>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleFileUpload}
        disabled={!selectedFile}
      >
        Enviar Arquivo
      </Button>
    </Box>
  );
};
