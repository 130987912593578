import React, { useEffect, useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import api from "../../../utils/api";

export const InstituteSelect = ({ value, onChange }) => {
  const [institutes, setInstitutes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInstitutes = async () => {
      setLoading(true);
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstitutes();
  }, []);

  return loading ? (
    <CircularProgress size={24} />
  ) : (
    <TextField
      select
      label="Selecione uma instituição"
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
    >
      {institutes.length ? (
        institutes.map((institute) => (
          <MenuItem key={institute.id} value={institute.id}>
            {institute.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>Nenhuma instituição encontrada</MenuItem>
      )}
    </TextField>
  );
};
