import React, { useEffect, useState } from "react";
import PanZoom from "react-easy-panzoom";
import api from "../../utils/api";
import { Document, Page, pdfjs } from "react-pdf";

// Configura o worker para o PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const ImageVisualize = ({ data, folder }) => {
  const [fileBlob, setFileBlob] = useState(null);
  const [document, setDocument] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [fileExist, setFileExist] = useState(false);
  const [fileType, setFileType] = useState(""); // Novo estado para tipo de arquivo

  useEffect(() => {
    switch (folder) {
      case "cpf":
        setDocument(data?.cpfImage || "");
        break;
      case "compMatricula":
        setDocument(
          data?.comprovanteImage || data?.profile?.comprovanteImage || ""
        );
        break;
      case "foto":
        setDocument(data?.carteirinhaImage || "");
        break;
      case "residencia":
        setDocument(data?.compResidencia || "");
        break;
      case "titeleitor":
        setDocument(data?.tituloEleitoralImage || "");
        break;
      case "rg":
        setDocument(data?.rgImage || "");
        break;
      case "payment":
        setDocument(data?.payment || data?.newPayment || "");
        break;
      case "pagamento":
        setDocument(data?.payment || data?.newPayment || "");
        break;
      case "newPayment":
        setDocument(data?.newPayment || "");
        break;
      default:
        setDocument("");
    }
  }, [folder, data]);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await api.get(`/uploads/${folder}/${document}`, {
          responseType: "blob",
        });

        const fileUrl = URL.createObjectURL(response.data);
        setFileBlob(fileUrl);
        setFileType(response.data.type); // Define o tipo de arquivo com base no response
        setFileExist(true);
      } catch (error) {
        console.error("Erro ao carregar o arquivo", error);
        setFileExist(false);
      }
    };

    if (document) {
      fetchFile();
    }
  }, [document, folder]);

  const handlePanChange = (positionX, positionY) => {
    setPosition({ x: positionX, y: positionY });
  };

  return fileExist ? (
    <div
      className="flex items-center justify-center"
      style={{ maxHeight: "80%" }}
    >
      {fileType === "application/pdf" ? (
        <div style={{ width: "80%", height: "100%" }}>
          <Document file={fileBlob}>
            <Page pageNumber={1} />
          </Document>
        </div>
      ) : (
        <PanZoom
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            height: "100%",
          }}
          boundaryRatioVertical={0.8}
          boundaryRatioHorizontal={0.8}
          enableBoundingBox
          positionX={position.x}
          positionY={position.y}
          onPanChange={handlePanChange}
        >
          <img
            src={fileBlob}
            alt="Documento"
            className="w-4/5 bg-red-600 h-auto rounded-md shadow-lg"
            style={{ display: "block", margin: "auto" }}
          />
        </PanZoom>
      )}
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center">
      <h1>Documento não encontrado</h1>
    </div>
  );
};
