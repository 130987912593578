import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import api from "../../../utils/api";
import { saveAs } from "file-saver";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useNavigate } from "react-router-dom";

export const AdminDeclaracoes = () => {
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [fileType, setFileType] = useState(""); // Estado para filtro por tipo de arquivo
  const [sortOrder, setSortOrder] = useState("desc"); // Estado para ordenação
  const [sortColumn, setSortColumn] = useState("createdAt"); // Estado para coluna de ordenação
  const { showFlashMessage } = useFlashMessage();
  const navigate = useNavigate();
  const fetchFiles = async () => {
    try {
      const response = await api.get("/admin/files"); // Substitua pela URL correta da sua API
      const sortedFiles = sortFiles(response.data, "desc", "createdAt");
      setFiles(sortedFiles);
      setFilteredFiles(sortedFiles);
    } catch (error) {
      console.error("Erro ao buscar arquivos:", error);
    }
  };
  useEffect(() => {
    fetchFiles();
  }, []);

  // const handleDownload = async (fileName) => {
  //   try {
  //     const response = await api.get(`/admin/files/download/${fileName}`, {
  //       responseType: "arraybuffer", // Para funcionar corretamente em downloads
  //     });

  //     const blob = new Blob([response.data]);
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     console.error("Erro ao baixar arquivo:", error);
  //   }
  // };
  const handleDownload = (fileName) => {
    api({
      url: `/admin/files/download/${fileName}`, // Substitua pela URL correta para download do arquivo
      method: "GET",
      responseType: "blob", // Importante para baixar arquivos
    })
      .then((response) => {
        saveAs(response.data, fileName);
      })
      .catch((error) => {
        console.error("Erro ao baixar arquivo:", error);
      });
  };

  const handleFileTypeChange = (event) => {
    const selectedType = event.target.value;
    setFileType(selectedType);
    filterFiles(selectedType, sortOrder, sortColumn);
  };

  const handleSortRequest = (column) => {
    const isAsc = sortColumn === column && sortOrder === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setSortOrder(newOrder);
    setSortColumn(column);
    filterFiles(fileType, newOrder, column);
  };

  const filterFiles = (type, order, column) => {
    let filtered = files;
    if (type) {
      filtered = files.filter((file) =>
        file.fileName.toLowerCase().endsWith(type)
      );
    }
    const sorted = sortFiles(filtered, order, column);
    setFilteredFiles(sorted);
  };

  const sortFiles = (filesToSort, order, column) => {
    return filesToSort.sort((a, b) => {
      if (column === "fileName") {
        return order === "asc"
          ? a.fileName.localeCompare(b.fileName)
          : b.fileName.localeCompare(a.fileName);
      } else {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return order === "asc" ? dateA - dateB : dateB - dateA;
      }
    });
  };
  const handleGenerateFiles = () => {
    api
      .get("/admin/declaracoes/declaracoes_file")
      .then((response) => {
        console.log(response);
        fetchFiles();
        showFlashMessage(response.data.message, "success");
      })
      .catch((err) => {
        const errMessage = err?.request?.response
          ? JSON.parse(err?.request?.response)
          : null;

        console.error("errMessage", errMessage?.error || "Erro");

        showFlashMessage(errMessage?.error || "Erro", "error");

        fetchFiles();
      });
  };
  return (
    <div style={{ minHeight: "80vh" }}>
      <Container style={{ padding: "2rem" }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/manager/declaracoes/errors");
              }}
            >
              Consultar Erros
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              onClick={() => {
                handleGenerateFiles();
              }}
            >
              Gerar Arquivos
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container style={{ padding: "2rem" }}>
        <Typography variant="h4" gutterBottom>
          Lista de Arquivos
        </Typography>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: 2 }}
        >
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="file-type-label">Filtrar por tipo</InputLabel>
              <Select
                labelId="file-type-label"
                value={fileType}
                onChange={handleFileTypeChange}
              >
                <MenuItem value="">Todos</MenuItem>
                <MenuItem value=".pdf">PDF</MenuItem>
                <MenuItem value=".zip">ZIP</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === "fileName"}
                    direction={sortOrder}
                    onClick={() => handleSortRequest("fileName")}
                  >
                    Nome do Arquivo
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortColumn === "createdAt"}
                    direction={sortOrder}
                    onClick={() => handleSortRequest("createdAt")}
                  >
                    Data de Criação
                  </TableSortLabel>
                </TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFiles.map((file) => {
                if (file.fileName !== ".gitignore") {
                  return (
                    <TableRow key={file.fileName}>
                      <TableCell>{file.fileName}</TableCell>
                      <TableCell>
                        {new Date(file.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          onClick={() => handleDownload(file.fileName)}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};
