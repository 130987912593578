import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import jsPDF from "jspdf";
import api from "../../../../utils/api";

export const PassengerReport = () => {
  const [queryParam, setQueryParam] = useState("");
  const urlSearchParams = new URLSearchParams(window.location.search);

  const fetchProfiles = async () => {
    console.log("fetchProfiles ->");

    const response = await api.get(`/admin/reports/passenger-report`);
    return response.data.profiles;
  };

  const fetchLines = async () => {
    console.log("fetchLines ->");
    const response = await api.get("/admin/bus/line");
    return response.data.busLines;
  };

  useEffect(() => {
    const paramValue = urlSearchParams.get("line");
    setQueryParam(paramValue);
  }, []);

  const {
    data: profiles,
    error: profilesError,
    isLoading: profilesLoading,
  } = useQuery({
    queryKey: ["profiles"],
    queryFn: fetchProfiles,
  });

  const {
    data: lines,
    error: linesError,
    isLoading: linesLoading,
  } = useQuery({
    queryKey: ["lines"],
    queryFn: fetchLines,
  });

  const handleGeneratePDF = async (data) => {
    const doc = new jsPDF({ orientation: "landscape" });
    const headers = [["ID", "Nome", "RG", "Linha", "Curso", "Turno"]];
    const rows = data.map((profile) => [
      profile?.id || "",
      profile?.name || "",
      profile?.rg || "",
      profile?.busLine?.name || "",
      profile?.CourseOnInstitute?.course?.name || "",
      profile?.CourseOnInstitute?.course?.period?.name || "",
    ]);
    const currentDate = new Date().toLocaleDateString();

    doc.autoTable({
      head: headers,
      body: rows,
      startY: 30,
      didDrawPage: (data) => {
        doc.text(
          `Data do Relatório: ${currentDate}`,
          14,
          doc.internal.pageSize.height - 10
        );
      },
    });

    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const newWindow = window.open(pdfUrl, "_blank");
    if (!newWindow) {
      throw new Error("Erro ao abrir nova janela.");
    }
  };

  if (profilesLoading || linesLoading) return <div>Loading...</div>;
  if (profilesError || linesError) return <div>Error fetching data</div>;
  console.log("profiles -->", profiles);
  console.log("lines -->", lines);
  const currentDate = new Date().toLocaleDateString();

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div></div>
        <Button
          onClick={() => handleGeneratePDF(profiles)}
          variant="contained"
          color="primary"
          sx={{ margin: "1rem" }}
        >
          Baixar PDF
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50px", textAlign: "left" }}></TableCell>
            <TableCell style={{ textAlign: "left" }}>Nome</TableCell>
            <TableCell style={{ textAlign: "left" }}>RG</TableCell>
            <TableCell style={{ textAlign: "left" }}>Curso</TableCell>
            <TableCell style={{ textAlign: "left" }}>Turno</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles?.map((profile, index) => (
            <TableRow key={profile.id}>
              <TableCell style={{ width: "50px", textAlign: "left" }}>
                {index + 1}
              </TableCell>
              <TableCell style={{ width: "50px", textAlign: "left" }}>
                {profile?.name}
              </TableCell>
              <TableCell style={{ width: "50px", textAlign: "left" }}>
                {profile?.rg}
              </TableCell>
              <TableCell style={{ width: "50px", textAlign: "left" }}>
                {profile?.CourseOnInstitute?.course?.name}
              </TableCell>
              <TableCell style={{ width: "50px", textAlign: "left" }}>
                {profile?.CourseOnInstitute?.course?.period?.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography variant="body2" align="center" style={{ marginTop: "20px" }}>
        Data do Relatório: {currentDate}
      </Typography>
    </div>
  );
};
