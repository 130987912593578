import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const BaseModal = ({
  open,
  handleClose,
  handleSubmit,
  title = "Default Title", // Título padrão
  children, // Conteúdo dinâmico passado como children
  submitButtonText = "Submit", // Texto do botão de envio
  cancelButtonText = "Cancel", // Texto do botão de cancelamento
  showActions = true, // Opção para exibir ou não botões de ação
  modalWidth = "70%", // Largura do modal
  modalBgColor = "#f5f5f5", // Cor de fundo do modal
  modalSx = {}, // Estilos adicionais customizados
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "relative", // Para posicionar o botão de fechar
          width: modalWidth,
          margin: "auto",
          marginTop: "5%",
          bgcolor: modalBgColor,
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          ...modalSx, // Permite sobrescrever estilos
        }}
      >
        {/* Botão de fechar */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Título do modal */}
        <Typography variant="h6" mb={2}>
          {title}
        </Typography>

        {/* Conteúdo dinâmico passado por children */}
        <Box mb={2}>{children}</Box>

        {/* Botões de ação */}
        {showActions && (
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                {cancelButtonText}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleSubmit}>
                {submitButtonText}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};
