import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Button,
  Container,
  Typography,
  Box,
} from "@mui/material";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { BusLineSelect } from "../../components/Inputs/BusLineSelect";
import { InstituteSelect } from "../../components/Inputs/InstituteSelect";
import { ModalidadeSelect } from "../../components/Inputs/ModalidadeSelect";
import { CourseSelect } from "../../components/Forms/inputs/CourseSelect";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useNavigate } from "react-router-dom";

export const CadastroForm = () => {
  const [token] = useState(
    localStorage.getItem("academicos-mallet@token") || ""
  );
  const { userId } = useAuth();
  const [profile, setProfile] = useState({});
  const [mode, setMode] = useState("cadastro");
  const { showFlashMessage } = useFlashMessage();
  const [busLines, setBusLines] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!profile.courseId) {
      profile.courseId = profile?.CourseOnInstitute?.course?.id;
    }
    try {
      const response =
        mode === "update"
          ? await api.put("/admin/profile", profile, getHeaders())
          : await api.post("/admin/profile", profile, getHeaders());

      showFlashMessage("Cadastrado com sucesso!", "success");
      navigate("/cadastro", { replace: true });
    } catch (error) {
      const message =
        error?.response?.data?.error?.message || "Falha ao cadastrar!";
      showFlashMessage(message, "error");
    }
  };

  const getHeaders = () => ({
    headers: {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    if (userId === 0) return;
    const fetchData = async () => {
      try {
        const response = await api.get(`/admin/profile/${userId}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
            "Content-Type": "application/json",
          },
        });
        setProfile(response.data?.profiles[0] || {});
        if (response.data?.profiles[0]) setMode("update");
      } catch (error) {
        console.error("Erro ao carregar o perfil:", error);
      }
    };
    fetchData();
  }, [token, userId]);

  useEffect(() => {
    const fetchBusLines = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };
    fetchBusLines();
  }, []);

  useEffect(() => {
    const fetchInstitutes = async () => {
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };
    fetchInstitutes();
  }, []);

  useEffect(() => {
    if (profile?.instituteId) {
      const fetchCourses = async () => {
        try {
          const response = await api.get(
            `/admin/course/institute/${profile?.instituteId}`
          );
          const courseList = response.data.institutesCourses.map(
            (course) => course.course
          );
          setCourses(courseList);
        } catch (error) {
          console.error("Erro ao buscar cursos:", error);
        }
      };
      fetchCourses();
    }
  }, [profile?.instituteId]);

  if (userId === 0) {
    return <Typography>Carregando...</Typography>;
  }

  return (
    <Container maxWidth="sm">
      <Box component="form" onSubmit={handleSubmit} mt={4}>
        <Typography variant="h4" gutterBottom>
          {mode === "update" ? "Atualizar Perfil" : "Cadastro"}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome Completo"
              name="name"
              value={profile?.name || ""}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-mail"
              name="email"
              value={profile?.email || ""}
              onChange={handleChange}
              type="email"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="CPF"
              name="cpf"
              value={profile?.cpf || ""}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="RG"
              name="rg"
              value={profile?.rg || ""}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Título de Eleitor"
              name="tituloEleitor"
              value={profile?.tituloEleitor || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Telefone"
              name="phone"
              value={profile?.phone || ""}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <BusLineSelect
              busLines={busLines}
              selectedId={profile?.busLineId || ""}
              name="busLineId"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <InstituteSelect
              institutes={institutes}
              selectedId={profile?.instituteId || ""}
              name="instituteId"
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <CourseSelect
              courses={courses}
              selectedCourse={
                profile?.courseId || profile?.CourseOnInstitute?.courseId
              }
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <ModalidadeSelect
              name="modalidade"
              selectedModalidade={profile?.modalidade || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {mode === "update" ? "Atualizar" : "Cadastrar"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
