import { ThemeProvider } from "styled-components";
import { Router } from "./routes/routes";
import { defaultTheme } from "./styles/themes/default";
import { GlobalStyle } from "./styles/global";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

export function App() {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <Router />
        <GlobalStyle />
      </ThemeProvider>
    </QueryClientProvider>

  );
}
