import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Box,
  Typography,
  Modal,
  IconButton,
  Chip,
} from "@mui/material";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { ImageUploadModal } from "../../Modals/ImageUploadImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageVisualize } from "../../Visualize/ImageVisualize";

import { ExpandedComponent } from "./ExpandedComponent";
import { deletePayment } from "../../../services/paymentServices";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const MensalidadeTable = ({
  fees,
  filters,
  totalPayments = 0,
  onMonthChange = (month) => {},
  onOrderChange = () => {},
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    months[new Date().getMonth()]
  );
  const [viewPdfModalOpen, setViewPdfModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [uploadFormModalOpen, setUploadFormModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const { showFlashMessage } = useFlashMessage();
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [selectedUpFolder, setSelectedUpFolder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("/admin/institute");
        console.log("result institutes", result);
        const inst = result.data.institutes.find(
          (institute) => institute.id === 1
        );
        console.log("result inst", inst);
        setInstitutes(result.data.institutes);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    const selectedMonthIndex = months.indexOf(month);
    onMonthChange(selectedMonthIndex);
  };

  const handleOpenUploadForm = (id) => {
    console.log("row -->", id);
    setUploadFormModalOpen(true);
    setSelectedRowId(id);
  };

  const handleVisualize = (url, folder) => {
    setPdfUrl(`${process.env.REACT_APP_API}uploads/pagamento/${url}`);
    setSelectedFileUrl(url);
    setSelectedUpFolder(folder);
    setViewPdfModalOpen(true);
  };

  const handleIsPay = async (id) => {
    try {
      const selectedRegister = data.find((register) => register.id === id);
      const message = selectedRegister?.isConfirmed
        ? "Aprovação desfeita!"
        : "Pagamento Aprovado!";

      await api.put("/admin/payments", { paymentId: id });

      setData((prevState) =>
        prevState.map((register) =>
          register.id === id
            ? { ...register, isConfirmed: !register.isConfirmed }
            : register
        )
      );

      showFlashMessage(message, "success");

      // updateTable();
    } catch (error) {
      showFlashMessage("Falha ao aprovar pagamento!", "error");
      console.error("Erro ao aprovar pagamento", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm("Deseja realmente excluir este curso?");
    if (!confirmation) return;

    try {
      await deletePayment(id).then(() => {
        showFlashMessage("Exclusão bem-sucedida!", "success");
      });
      // window.location.reload();
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("/admin/payments");
        setData(result.data.payments);
      } catch (error) {
        console.error("Erro ao obter pagamentos", error);
      }
    };
    fetchData();
  }, [institutes]);

  const handleSort = (column, sortDirection) => {
    onOrderChange(column, sortDirection);
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);
    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  const updateTable = async () => {
    try {
      const result = await api.get("/admin/payments");
      setData(result.data.payments);
    } catch (error) {
      console.error("Erro ao atualizar tabela", error);
    }
  };

  const [monthCounts, setMonthCounts] = useState({});
  const StatusPill = ({ isInactive }) => (
    <Chip
      label={isInactive ? "Inativo" : "Ativo"}
      style={{
        backgroundColor: isInactive ? "#f44336" : "#4caf50", // vermelho para inativo, verde para ativo
        color: "#fff",
        fontWeight: "bold",
        padding: "0 10px",
      }}
    />
  );
  useEffect(() => {
    try {
      if (institutes?.length > 0) {
        const counts = months?.reduce((acc, month) => {
          const filteredData = data?.filter((fee) => {
            const dueDate = new Date(fee.dueDate);
            return dueDate.getMonth() === months.indexOf(month);
          });
          acc[month] = filteredData.length;
          return acc;
        }, {});

        setMonthCounts(counts);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, [data, months, institutes]);

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        width: "80px",
      },
      {
        name: "Status",
        selector: (row) => (
          <StatusPill isInactive={row.user?.Flags[0]?.inativeUser} />
        ),

        width: "140px",
      },
      {
        name: "Acadêmico",
        selector: (row) => row.user?.Profile[0]?.name,
        sortable: true,
        width: "200px",
      },
      {
        name: "Instituição",
        selector: (row) =>
          row.user?.Profile[0]?.institute?.name
            ? row.user?.Profile[0]?.institute?.name
            : "Sem instituição cadastrada",
        width: "200px",
      },
      // {
      //   name: "Instituição",
      //   selector: (row) =>
      //     row?.user?.Profile[0]?.instituteId
      //       ? findInstitute(row?.user?.Profile[0]?.instituteId)
      //       : "Sem instituição cadastrada",
      //   width: "200px",
      // },
      {
        name: "Data de vencimento",
        selector: (row) => {
          const dueDate = new Date(row.dueDate);
          return dueDate.toLocaleDateString("pt-BR");
        },
        width: "150px",
      },
      {
        name: "Valor",
        selector: (row) => {
          const formattedAmount =
            row?.amount?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }) || "R$ 0,00";
          return formattedAmount;
        },
        width: "150px",
      },
      {
        name: "Status",
        selector: (row) =>
          row?.imageUrl
            ? row?.isConfirmed
              ? "Pago"
              : "Pendente" || ""
            : "Comprovante não enviado",
        sortable: true,
        width: "150px",
      },
      {
        name: "Ações",
        cell: (row) => (
          <IconButton
            color="error"
            onClick={() => handleDelete(row.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        ),
        width: "100px",
      },
    ],
    []
  );

  useEffect(() => {
    let filteredData = fees.filter((fee) => {
      const dueDate = new Date(fee.dueDate);
      const feeMonth = dueDate.getMonth();
      const selectedMonthIndex = months.indexOf(selectedMonth);

      if (filters.name && filters.name.trim() !== "") {
        const normalizedFilter = filters.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const userName = fee.user?.Profile[0]?.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return userName?.includes(normalizedFilter);
      }

      return true;
    });

    if (filters?.paymentStatus) {
      if (filters?.paymentStatus === "pending") {
        filteredData = filteredData.filter((fee) => fee?.isConfirmed === false);
      }
      if (filters?.paymentStatus === "paid") {
        filteredData = filteredData.filter((fee) => fee?.isConfirmed === true);
      }
    }

    const counts = months.reduce((acc, month) => {
      const filtered = filteredData.filter((fee) => {
        const dueDate = new Date(fee.dueDate);
        return dueDate.getMonth() === months.indexOf(month);
      });

      acc[month] = filtered.length;
      return acc;
    }, {});
    setMonthCounts(counts);

    setData(filteredData);
  }, [filters, fees, selectedMonth]);
  useEffect(() => {
    console.log("filters ->", filters);
  }, [filters]);
  return (
    <Box sx={{ backgroundColor: "#f0f0f0", width: "100%", borderRadius: 2 }}>
      <Box
        component="nav"
        sx={{ overflowX: "auto", p: 2, backgroundColor: "#f0f0f0" }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          {months.map((month) => (
            <Button
              key={month}
              onClick={() => handleMonthClick(month)}
              variant={selectedMonth === month ? "contained" : "outlined"}
            >
              {month}
            </Button>
          ))}
        </Box>
      </Box>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Total de lançamentos: {totalPayments}
        </Typography>
        <DataTable
          columns={columns}
          data={data}
          expandableRows
          expandableRowsComponent={(row) => (
            <ExpandedComponent
              data={row.data}
              handleOpenUploadForm={handleOpenUploadForm}
              handleVisualize={handleVisualize}
              handleIsPay={handleIsPay}
            />
          )}
          expandableRowExpanded={(row) => expandedRows.includes(row.id)}
          onRowClicked={handleRowClick}
          onSort={handleSort}
          sortServer
        />
      </Box>
      <Modal
        open={viewPdfModalOpen}
        onClose={() => setViewPdfModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Typography id="file-modal-title" variant="h6" component="h2">
            Visualização de Arquivo
          </Typography>
          {selectedFileUrl && (
            <div>
              {selectedFileUrl.endsWith(".pdf") ? (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              ) : (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              )}
            </div>
          )}
        </Box>
      </Modal>

      <ImageUploadModal
        isOpen={uploadFormModalOpen}
        onRequestClose={() => {
          setUploadFormModalOpen(false);
        }}
        header={`Comp. de pagamento - ${selectedMonth}`}
        folder="newPayment"
        paymentId={selectedRowId.id}
        month={selectedMonth}
      />
    </Box>
  );
};
