import React from "react";

import { ImageUploadForm } from "../../../pages/Cadastro/ImageUploadForm";
import { BaseModal } from "../BaseModal";

const modalStyle = {
  width: {
    xs: "90%", // 90% da largura em telas pequenas
    sm: 400, // 400px em telas médias e grandes
  },
  p: {
    xs: 2, // Padding menor em telas pequenas
    sm: 4, // Padding maior em telas médias e grandes
  },
};

export const ImageUploadModal = ({
  isOpen,
  onRequestClose,
  header,
  folder,
  paymentId,
  month,
}) => {
  return (
    <BaseModal
      open={isOpen}
      handleClose={onRequestClose}
      title={header} // Passando o título como prop
      modalSx={modalStyle} // Estilos personalizados do modal
      showActions={false} // Ocultando os botões de ação
    >
      {/* Conteúdo dinâmico dentro do modal */}
      <ImageUploadForm
        folder={folder}
        paymentId={paymentId}
        month={month}
        update={onRequestClose}
      />
    </BaseModal>
  );
};
