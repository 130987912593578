import React, { useState, useEffect } from "react";
import { Modal } from "../Modal";
import { ImageVisualize } from "../../Visualize/ImageVisualize";
import { Button } from "@mui/material";
import { ImageUploadForm } from "../../../pages/Cadastro/ImageUploadForm";

export const DocumentsModal = ({
  isOpen,
  onClose,
  data,
  update = () => {},
}) => {
  const [buttonOrigin, setButtonOrigin] = useState("");
  const [content, setContent] = useState(null);
  const [uploadForm, setUploadForm] = useState(false); // Definir inicialmente como false
  const headerText = {
    foto: "Envie uma foto do acadêmico",
    cpf: "Envie uma imagem do CPF",
    rg: "Envie uma imagem do RG",
    residencia: "Envie uma imagem do Comp. de residência",
    compMatricula: "Envie uma imagem do comprovante de matricula",
    titeleitor: "Envie uma imagem do titulo de eleitor",
  };
  const headerFolder = {
    foto: "foto",
    cpf: "cpf",
    rg: "rg",
    residencia: "residencia",
    compMatricula: "compMatricula",
    titeleitor: "titeleitor",
  };
  useEffect(() => {
    console.log("data -->", data);
    if (buttonOrigin !== "") {
      setContent(<ImageVisualize data={data} folder={buttonOrigin} />);
    } else {
      setContent(null); // Limpar o conteúdo quando buttonOrigin for uma string vazia
    }
  }, [buttonOrigin, data]);

  const handleButtonClick = (origin) => {
    setButtonOrigin(origin);
    setUploadForm(false); // Resetar o estado do uploadForm ao selecionar um novo botão
  };

  if (!isOpen) {
    return null;
  }
  const modalUpdate = async () => {
    await update();
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setButtonOrigin("");
      }}
      style={{
        maxWidth: "90vw",
        maxHeight: "90vh",
        overflowY: "auto", // Permite rolagem vertical se o conteúdo for maior que o modal
      }}
    >
      <nav className="flex flex-wrap justify-center items-center mb-4">
        <ul className="flex flex-wrap justify-center">
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.cpfImage === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("foto")}
            >
              Foto 3x4
            </Button>
          </li>
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.cpfImage === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("cpf")}
            >
              CPF
            </Button>
          </li>
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.rgImage === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("rg")}
            >
              RG
            </Button>
          </li>
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.compResidencia === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("residencia")}
            >
              Comp. de residência
            </Button>
          </li>
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.comprovanteImage === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("compMatricula")}
            >
              Comp. Matricula
            </Button>
          </li>
          <li className="m-2">
            <Button
              variant="contained"
              color={data?.tituloEleitoralImage === null ? "error" : "primary"}
              size="small"
              onClick={() => handleButtonClick("titeleitor")}
            >
              Titulo de eleitor
            </Button>
          </li>
        </ul>
      </nav>

      <div style={{ maxHeight: "65vh", minHeight: "65vh", overflowY: "auto" }}>
        {/* {JSON.stringify(data)} */}
        {uploadForm ? (
          <ImageUploadForm
            header={headerText[buttonOrigin] || "none"}
            folder={headerFolder[buttonOrigin] || "none"}
            userId={data?.userId || data?.profile?.userId || null}
            update={modalUpdate}
          />
        ) : (
          <div
            style={{
              maxHeight: "50vh",
              minHeight: "50vh",
              overflowY: "auto",
            }}
          >
            {content}
          </div>
        )}

        {buttonOrigin && (
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button
              variant="contained"
              onClick={() => {
                setUploadForm(!uploadForm); // Alterna entre exibir o formulário de upload ou o conteúdo
                console.log("data ->", uploadForm, buttonOrigin);
                console.log("data headerText->", headerText[buttonOrigin]);
                console.log("data headerText->", headerFolder[buttonOrigin]);
              }}
            >
              {uploadForm ? "Cancelar" : "Enviar"}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
