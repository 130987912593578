import React from "react";
import { Button } from "@mui/material";

export const TabButton = ({ active, label, onClick, controls }) => (
  <Button
    variant="text"
    color={active ? "primary" : "inherit"}
    onClick={onClick}
    aria-controls={controls}
    aria-selected={active}
    sx={{
      padding: "12px",
      borderBottom: active ? "2px solid" : "2px solid transparent",
      borderRadius: 0,
      color: active ? "primary.main" : "text.secondary",
    }}
  >
    {label}
  </Button>
);
