import React from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const ExpandedComponent = ({
  data,
  handleOpenUploadForm,
  handleVisualize,
  handleIsPay,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderImageButtons = () => (
    <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
      {data?.imageUrl && (
        <ImageButton
          label={data.imageUrl}
          onClick={() => handleVisualize(data.imageUrl, "pagamento")}
        />
      )}
      {data?.user?.Profile?.[0]?.UploadedFiles?.map((file, index) => (
        <ImageButton
          key={index}
          label={file.filename}
          onClick={() => handleVisualize(file.filename, "newPayment")}
        />
      ))}
    </Box>
  );

  return (
    <Box display="flex" flexDirection="column" p={isMobile ? 1 : 2} gap={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenUploadForm(data)}
      >
        Cadastrar comprovante
      </Button>

      {renderImageButtons()}

      {data?.imageUrl && (
        <Button
          variant="contained"
          color={data.isConfirmed ? "error" : "primary"}
          onClick={() => handleIsPay(data.id)}
          sx={{ mt: 2 }}
        >
          {data.isConfirmed ? "Desaprovar pagamento" : "Considerar pago"}
        </Button>
      )}
    </Box>
  );
};

const ImageButton = ({ label, onClick }) => (
  <IconButton
    variant="contained"
    color="secondary"
    onClick={onClick}
    aria-label="visualize"
  >
    <VisibilityIcon sx={{ mr: 1 }} />
    <Typography variant="body2" noWrap>
      {label}
    </Typography>
  </IconButton>
);
