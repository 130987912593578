import React, { useEffect, useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import api from "../../../utils/api";

export const BusLineSelect = ({ value, onChange }) => {
  const [busLines, setBusLines] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBusLines = async () => {
      setLoading(true);
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBusLines();
  }, []);

  return loading ? (
    <CircularProgress size={24} />
  ) : (
    <TextField
      select
      label="Selecione uma linha de ônibus"
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
    >
      {busLines.length ? (
        busLines.map((busLine) => (
          <MenuItem key={busLine.id} value={busLine.id}>
            {busLine.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>Nenhuma linha de ônibus encontrada</MenuItem>
      )}
    </TextField>
  );
};
