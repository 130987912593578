import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import api from "../utils/api";

export default function useFlags() {
  const { userId } = useAuth();
  const [flags, setFlags] = useState({});
  const [loading, setLoading] = useState(true);
  const updateFlags = async () => {
    const fetch = async () => {
      console.log("fetch update");
      await api
        .get(`/admin/flags/${userId}`)
        .then((response) => {
          // console.log("response flags", response.data);
          setFlags(response.data.flags);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    };
    if (userId) {
      fetch();
    }
  };
  useEffect(() => {
    const fetch = async () => {
      await api
        .get(`/admin/flags/${userId}`)
        .then((response) => {
          // console.log("response flags", response.data);
          setFlags(response.data.flags);
          setLoading(false);
        })
        .catch((error) => console.error(error));
    };
    if (userId) {
      fetch();
    }
  }, [userId]);

  return { flags, loading, updateFlags };
}
