// TermsPage.js
import React, { useState, useEffect, useCallback } from "react";
import { TermsContent } from "./TermsContent";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useFlags from "../../hooks/userFlags";

export const TermsPage = ({ onAccept = () => {} }) => {
  const [accepted, setAccepted] = useState(false);
  const { updateFlags } = useFlags();
  const navigate = useNavigate();
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);
  const { userId } = useAuth();

  const acceptTerm = () => {
    setAccepted(true);
  };
  const handleAccept = () => {
    if (isScrollAtBottom) {
      acceptTerm();
      const data = { userId: userId, acceptedTerms: true };

      api
        .put("/admin/flags/", data)
        .then((response) => {
          updateFlags();
        })
        .then(() => {
          navigate("/home");
        })
        .catch((error) => console.error(error));
      onAccept();
    }
  };

  const handleScroll = useCallback(() => {
    const container = document.querySelector(".overflow-y-scroll");
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const atBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight - 2;

    setIsScrollAtBottom(atBottom);
    // setIsScrollAtBottom(true);
  }, []);

  useEffect(() => {
    const container = document.querySelector(".overflow-y-scroll");
    if (!container) return;

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen wf bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md max-w-xl w-full">
        <h1 className="flex items-center justify-center text-3xl font-bold mb-6">
          Termos e condições de uso
        </h1>
        <div className="overflow-y-scroll mb-4" style={{ maxHeight: "60vh" }}>
          <TermsContent />
        </div>
        <div className="flex justify-center items-center">
          <button
            onClick={handleAccept}
            disabled={!isScrollAtBottom}
            className={`px-4 py-2 bg-blue-500 text-white rounded ${
              !isScrollAtBottom ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {isScrollAtBottom
              ? "Aceitar Termos"
              : "Você deve ler os termos antes de aceitar"}
          </button>
        </div>
      </div>
    </div>
  );
};
