import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import api from "../../../utils/api";
import { InstituteTable } from "../../../components/Tables/InstituteTable";
import { useModal } from "../../../hooks/useModal";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";

export const InstitutesPage = () => {
  const [institutes, setInstitutes] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  // Fetching the list of institutes from the API
  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = async () => {
    try {
      const response = await api.get("/admin/institute");
      setInstitutes(response.data.institutes);
    } catch (error) {
      showFlashMessage("Erro ao carregar as instituições.", "error");
      console.error("Erro ao buscar as instituições:", error);
    }
  };

  // Handler for opening the modal to add a new institute
  const handleInstituteModal = () => {
    openModal("institute-modal", {
      operation: null,
      onSuccess: fetchInstitutes,
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Page Title */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Instituições
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Gerencie as instituições cadastradas.
        </Typography>
      </Box>

      {/* Button for opening modal */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleInstituteModal}
        >
          Novo Cadastro
        </Button>
      </Box>

      {/* Institutes Table */}
      <InstituteTable institutes={institutes} />
    </Container>
  );
};
