import React, { useState, useEffect, useMemo, useCallback } from "react";
import api from "../../../utils/api";
import { ProfileTable } from "../../../components/Tables/ProfileTable";
import { Filters } from "../../../components/Tables/Filters/Filters";
import {
  Box,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ProfilePage = () => {
  const navigate = useNavigate();

  const filtersOptions = useMemo(
    () => ({
      WAITING: "waiting",
      APPROVED: "approved",
      ALL: "all",
    }),
    []
  );

  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [approvedFilter, setApprovedFilter] = useState(filtersOptions.WAITING);
  const [filters, setFilters] = useState({});

  const fetchProfiles = useCallback(async () => {
    try {
      const response = await api.get("/admin/profile");
      setProfiles(response.data.profiles || []);
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  }, []);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  useEffect(() => {
    const applyFilters = () => {
      const normalizedFilter = filters?.name
        ? filters?.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        : "";

      let filtered = profiles.filter((user) =>
        user.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(normalizedFilter)
      );

      if (filters?.instituteId) {
        filtered = filtered.filter(
          (user) => user.institute?.id === parseInt(filters.instituteId)
        );
      }

      if (filters?.busLineId) {
        filtered = filtered.filter(
          (user) => user.busLine?.id === parseInt(filters.busLineId)
        );
      }

      switch (approvedFilter) {
        case filtersOptions.WAITING:
          setFilteredProfiles(filtered.filter((user) => !user.isApproved));
          break;
        case filtersOptions.APPROVED:
          setFilteredProfiles(filtered.filter((user) => user.isApproved));
          break;
        case filtersOptions.ALL:
          setFilteredProfiles(filtered);
          break;
        default:
          setFilteredProfiles(filtered);
      }
    };

    applyFilters();
  }, [profiles, approvedFilter, filtersOptions, filters]);

  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#f4f6f8", p: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Acadêmicos
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <ToggleButtonGroup
          value={approvedFilter}
          exclusive
          onChange={(e, value) =>
            setApprovedFilter(value || filtersOptions.ALL)
          }
          color="primary"
          size="small"
        >
          <ToggleButton value={filtersOptions.ALL}>Todos</ToggleButton>
          <ToggleButton value={filtersOptions.APPROVED}>Aprovados</ToggleButton>
          <ToggleButton value={filtersOptions.WAITING}>
            Aguardando Aprovação
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/manager/todosusuarios")}
        >
          Usuários Duplicados
        </Button>
      </Box>

      <Filters
        filters={filters}
        setFilters={setFilters}
        busLineId
        instituteId
      />

      <ProfileTable profiles={filteredProfiles} updateList={fetchProfiles} />
    </Box>
  );
};
