import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const InstituteSelect = ({
  institutions,
  selectedInstitution,
  onChange,
}) => {
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel>Selecione a Instituição</InputLabel>
        <Select
          id="institute"
          name="institute"
          value={selectedInstitution}
          onChange={onChange}
          variant="outlined"
        >
          <MenuItem value="">Selecione a Instituição</MenuItem>
          {institutions?.map((institution) => (
            <MenuItem key={institution.id} value={institution.id}>
              {institution.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
