import { useEffect, useState } from "react";
import { Box, Button, Grid, CircularProgress, Typography } from "@mui/material";
import { FaDollarSign } from "react-icons/fa";
import { TextInput } from "../../Inputs/TextInput";
import { InstituteSelect } from "../../Inputs/InstituteSelect";
import DatePicker from "react-datepicker";
import api from "../../../utils/api";

export const PaymentByInstituteForm = () => {
  const [fee, setFee] = useState({ valor: "", instituteId: "" });
  const [institutions, setInstitutions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFee((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError("");

    const payload = {
      typeId: 2,
      amount: fee.valor,
      date: new Date().toISOString(),
      dueDate: selectedDate.toISOString(),
      instituteId: fee.instituteId,
    };

    try {
      const response = await api.post("/admin/payments", payload);
      setSuccess(true);
      console.log(response);
    } catch (err) {
      setError("Erro ao cadastrar pagamento. Tente novamente.");
      console.error("Erro ao cadastrar pagamento:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInstitutions = async () => {
      setLoading(true);
      try {
        const response = await api.get("/admin/institute");
        setInstitutions(response.data.institutes);
      } catch (error) {
        setError("Erro ao buscar instituições.");
        console.error("Erro ao buscar instituições:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInstitutions();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: "auto",
        p: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textAlign: "center", mb: 2 }}>
        Cadastro de Pagamento
      </Typography>

      {error && (
        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      {success && (
        <Typography color="success.main" variant="body2" sx={{ mb: 2 }}>
          Pagamento cadastrado com sucesso!
        </Typography>
      )}
      <Grid container spacing={2} sx={{ marginTop: "4px" }}>
        <Grid item xs={12}>
          <TextInput
            name="valor"
            value={fee.valor}
            onChange={handleChange}
            icon={<FaDollarSign />}
            placeholder="R$ 0,00"
            type="number"
            fullWidth
            sx={{ mb: 3 }} // Margem inferior para separação
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "4px" }}>
        <Grid item xs={12}>
          <DatePicker
            name="vencimento"
            selected={selectedDate}
            dateFormat="dd/MM/yyyy"
            onChange={handleDateChange}
            className="p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "4px" }}>
        <Grid item xs={12}>
          <InstituteSelect
            name="instituteId"
            selectedId={fee.instituteId}
            institutes={institutions}
            onChange={handleChange}
            sx={{ mb: 3 }} // Margem inferior para separação
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "4px" }}>
        <Grid item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              py: 1.5,
              fontWeight: "bold",
              borderRadius: 3,
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                backgroundColor: "#3c7dff",
                margin: "4px",
              },
              mb: 2, // Margem inferior para o botão
            }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Cadastrar"
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
