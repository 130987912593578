import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import api from "../../../../utils/api";
import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";

export const UsersByLine = () => {
  const [queryParam, setQueryParam] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [lines, setLines] = useState([]);
  const urlSearchParams = new URLSearchParams(window.location.search);
  let indice = 1;

  const handleGeneratePDF = async (data) => {
    // Cria um novo documento PDF
    const doc = new jsPDF({ orientation: "landscape" });

    // Adiciona conteúdo ao documento PDF

    // Define os cabeçalhos da tabela
    const headers = [["", "Nome", "RG", "Linha", "Curso", "Turno"]];

    // Mapeia os dados para o formato adequado para a tabela
    const rows = data.map((profile, index) => [
      index + 1 || "",
      profile?.name || "",
      profile?.rg || "",
      profile?.busLine?.name || "",
      profile?.CourseOnInstitute?.course?.name || "",
      profile?.CourseOnInstitute?.course?.period?.name || "",
    ]);

    // Adiciona a tabela ao documento PDF
    doc.autoTable({
      head: headers,
      body: rows,
      startY: 30, // Posição Y inicial da tabela
    });

    // Gera o arquivo temporário
    const pdfBlob = doc.output("blob");

    // Cria uma URL temporária para o blob do PDF
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Abre uma nova janela e carrega o PDF
    const newWindow = window.open(pdfUrl, "_blank");
    if (!newWindow) {
      throw new Error("Erro ao abrir nova janela.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar perfis e linhas da API
        const paramValue = urlSearchParams.get("line"); // Substitua 'paramName' pelo nome do seu query parameter

        setQueryParam(paramValue);

        // const response = await api.get("/admin/reports/coursexline");
        const response = await api
          .get(`/admin/profile?filters=busLineId=${paramValue}`)
          .then((response) => {
            setProfiles(response?.data?.profiles);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      await api
        .get(`/admin/bus/line`)
        .then((response) => {
          setLines(response?.data?.busLines);
        })
        .catch((error) => console.error(error));
    };
    fetchData();
  }, []);
  console.log("lines -->", lines);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div></div>
        <Button
          onClick={() => {
            handleGeneratePDF(profiles);
          }}
          variant="contained"
          color="primary"
          sx={{ margin: "1rem" }}
        >
          Baixar PDF
        </Button>
      </div>
      {lines?.map((line) => {
        const profilesInLine = profiles?.filter(
          (profile) => profile.busLineId === line.id
        );
        console.log("profilesInLine ->", profilesInLine);
        console.log("profilesInLine ->", profilesInLine);
        return (
          <div
            style={{
              border: "1px solid black",
              margin: "1rem",
              padding: "1rem",
              borderRadius: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div></div>
              <Button
                onClick={() => {
                  handleGeneratePDF(profilesInLine);
                }}
                variant="contained"
                color="primary"
                sx={{ margin: "1rem" }}
              >
                Baixar PDF
              </Button>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: "50px", textAlign: "left" }}
                  ></TableCell>
                  <TableCell style={{ textAlign: "left" }}>Nome</TableCell>
                  <TableCell style={{ textAlign: "left" }}>Curso</TableCell>
                  <TableCell style={{ textAlign: "left" }}>Turno</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {profilesInLine?.length &&
                  profilesInLine?.map((profile, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow key={profile.id}>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {profile?.name}
                          </TableCell>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {profile?.CourseOnInstitute?.course?.name}
                          </TableCell>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {profile?.CourseOnInstitute?.course?.period?.name}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        );
      })}
      {/* <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50px", textAlign: "left" }}>
              Id
            </TableCell>
            <TableCell style={{ textAlign: "left" }}>Nome</TableCell>
            <TableCell style={{ textAlign: "left" }}>Curso</TableCell>
            <TableCell style={{ textAlign: "left" }}>Turno</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles?.length &&
            profiles?.map((profile, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow key={profile.id}>
                    <TableCell style={{ width: "50px", textAlign: "left" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ width: "50px", textAlign: "left" }}>
                      {profile?.name}
                    </TableCell>
                    <TableCell style={{ width: "50px", textAlign: "left" }}>
                      {profile?.CourseOnInstitute?.course?.name}
                    </TableCell>
                    <TableCell style={{ width: "50px", textAlign: "left" }}>
                      {profile?.CourseOnInstitute?.course?.period?.name}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
        </TableBody>
      </Table> */}
      {/* <pre>
        {
          // JSON.stringify(profiles)
          profiles?.map((profile) => {
            return <p>{JSON.stringify(profile)}</p>;
          })
        }
      </pre> */}
    </div>
  );
};
