import React, { useState, useCallback } from "react";
import { TextField, Box, Grid } from "@mui/material";

import { InstituteSelect } from "./InstituteSelect";
import { BusLineSelect } from "./BusLineSelect";
import { UserSelect } from "../../Inputs/UserSelect";
import { FilterActions } from "./FiltersActions";
import { StatusSelect } from "./StatusSelect";
import { PaymentStatusSelect } from "./PaymentStatusSelect";

export const Filters = ({
  filters,
  setFilters,
  filterUserId = false,
  instituteId = false,
  busLineId = false,
  filterUserStatus = false,
  filterPaymentStatus = false,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggleExpand = () => setExpanded(!expanded);
  const handleClearFilters = () => setFilters({});

  const handleUserSelect = useCallback(
    (userId) => setFilters({ ...filters, userId }),
    [filters, setFilters]
  );

  const handleChange = (field) => (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: event?.target?.value,
    }));
  };

  return (
    <Box p={2}>
      <FilterActions
        onToggleExpand={handleToggleExpand}
        onClearFilters={handleClearFilters}
        expanded={expanded}
      />

      {expanded && (
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {filterUserId ? (
                <UserSelect onSelect={handleUserSelect} />
              ) : (
                <TextField
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  value={filters?.name || ""}
                  onChange={handleChange("name")}
                />
              )}
            </Grid>

            {instituteId && (
              <Grid item xs={12}>
                <InstituteSelect
                  value={filters?.instituteId || ""}
                  onChange={handleChange("instituteId")}
                />
              </Grid>
            )}

            {busLineId && (
              <Grid item xs={12}>
                <BusLineSelect
                  value={filters?.busLineId || ""}
                  onChange={handleChange("busLineId")}
                />
              </Grid>
            )}
            {filterUserStatus && (
              <Grid item xs={12}>
                <StatusSelect
                  value={filters?.status || "active"}
                  onChange={handleChange("status")}
                />
              </Grid>
            )}
            {filterPaymentStatus && (
              <Grid item xs={12}>
                <PaymentStatusSelect
                  value={filters?.paymentStatus || "all"}
                  onChange={handleChange("paymentStatus")}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
