import { useState } from "react";
import { Box, Grid } from "@mui/material";

import { PaymentByInstituteForm } from "../../../components/Forms/payments/PaymentByInstitute";
import { PaymentByUserForm } from "../../../components/Forms/payments/PaymentByUserForm";
import { TabButton } from "./TabButton";
import { TabPanel } from "./TabPanel";

export const FeesPanel = () => {
  const [activeTab, setActiveTab] = useState("profile");

  return (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Grid item>
          <TabButton
            active={activeTab === "profile"}
            label="Lançar cobranças por instituição"
            onClick={() => setActiveTab("profile")}
            controls="profile"
          />
        </Grid>
        <Grid item>
          <TabButton
            active={activeTab === "contacts"}
            label="Lançar cobranças individuais"
            onClick={() => setActiveTab("contacts")}
            controls="contacts"
          />
        </Grid>
      </Grid>

      <TabPanel active={activeTab === "profile"}>
        <PaymentByInstituteForm />
      </TabPanel>
      <TabPanel active={activeTab === "contacts"}>
        <PaymentByUserForm />
      </TabPanel>
    </Box>
  );
};
