import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Box, Modal, Checkbox, FormControlLabel } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";

const columnsConfig = [
  { key: "id", label: "ID" },
  { key: "name", label: "Nome" },
  { key: "phone", label: "Telefone" },
  { key: "email", label: "Email" },
  {
    key: "busLine",
    label: "Linha",
    selector: (row) => row?.busLine?.name || "N/A",
  },
  {
    key: "institute",
    label: "Instituição",
    selector: (row) => row?.institute?.name || "N/A",
  },
  {
    key: "course",
    label: "Curso",
    selector: (row) => row?.CourseOnInstitute?.course?.name || "N/A",
  },
  {
    key: "status",
    label: "Situação",
    selector: (row) =>
      row?.user?.Flags?.[0]?.inativeUser
        ? "Inativo"
        : row?.isApproved
        ? "Aprovado"
        : "Aguardando Aprovação",
  },
];

export const ProfileTable = ({ profiles = [], updateList = () => {} }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  // Estado para gerenciar as colunas selecionadas
  const [selectedColumns, setSelectedColumns] = useState(columnsConfig);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setData(Array.isArray(profiles) ? profiles : []);
  }, [profiles]);

  const toggleModal = () => setModalOpen((prev) => !prev);

  const handleColumnChange = (key) => {
    setSelectedColumns((prev) =>
      prev.some((col) => col.key === key)
        ? prev.filter((col) => col.key !== key)
        : [...prev, columnsConfig.find((col) => col.key === key)]
    );
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const tableHeaders = selectedColumns.map((col) => col.label);
    const tableData = data.map((row) =>
      selectedColumns.map((col) =>
        col.selector ? col.selector(row) : row[col.key] || "N/A"
      )
    );

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("relatorio_perfis.pdf");
    toggleModal(); // Fecha o modal após gerar o PDF
  };

  const columns = useMemo(
    () =>
      columnsConfig.map((col) => ({
        name: col.label,
        selector: col.selector || ((row) => row[col.key] || "N/A"),
        sortable: true,
      })),
    []
  );

  return (
    <Box>
      <Box mb={2}>
        <Button variant="contained" onClick={toggleModal}>
          Configurar Relatório
        </Button>
      </Box>
      <DataTable
        columns={columns}
        data={data}
        expandableRows
        expandableRowExpanded={(row) => expandedRows.includes(row?.id)}
        onRowClicked={(row) =>
          setExpandedRows((prev) =>
            prev.includes(row.id)
              ? prev.filter((id) => id !== row.id)
              : [...prev, row.id]
          )
        }
      />

      {/* Modal para selecionar colunas */}
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <h3>Selecione as colunas para o relatório</h3>
          <Box mb={2}>
            {columnsConfig.map((col) => (
              <FormControlLabel
                key={col.key}
                control={
                  <Checkbox
                    checked={selectedColumns.some(
                      (selected) => selected.key === col.key
                    )}
                    onChange={() => handleColumnChange(col.key)}
                  />
                }
                label={col.label}
              />
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={toggleModal}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={generatePDF}>
              Gerar PDF
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
