// services/paymentsService.js

import api from "../utils/api";

export const PaymentsService = {
    async getPaymentsData() {
        try {
            const response = await api.get("admin/dashboard/payments");
            return response.data.dashboard;
        } catch (error) {
            console.error("Erro ao buscar dados de pagamentos:", error);
            return [];
        }
    },
    async getPaymentsDetailsData() {
        try {
            const response = await api.get("admin/dashboard/paymentsdetails");
            return response.data.dashboard;
        } catch (error) {
            console.error("Erro ao buscar dados de pagamentos:", error);
            return [];
        }
    },
};
