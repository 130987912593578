import React, { useRef } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../../components/Header";

export function DefaultLayout() {
  const containerRef = useRef(null); // Referência ao contêiner principal

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col h-screen" ref={containerRef}>
      <Header />
      <div className="flex-grow bg-gray-800 p-8">
        <Outlet scrollToTop={scrollToTop} />
      </div>
    </div>
  );
}
