import React, { useState, useEffect, useCallback } from "react";
import api from "../../../utils/api";
import { useModal } from "../../../hooks/useModal";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { Button, Container, Typography, Box } from "@mui/material";
import { LineTable } from "../../../components/Tables/LineTable";

export const LineManagementPage = () => {
  const [lines, setLines] = useState([]);
  const { openModal } = useModal();
  const { showFlashMessage } = useFlashMessage();

  const fetchLines = useCallback(async () => {
    try {
      const response = await api.get("/admin/bus/line");
      setLines(response.data.busLines);
    } catch (error) {
      showFlashMessage("Erro ao carregar linhas", "error");
    }
  }, [showFlashMessage]);

  useEffect(() => {
    fetchLines();
  }, [fetchLines]);

  const handleLineModal = () => {
    openModal("line-modal", { operation: null });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" color="textPrimary" fontWeight={600}>
          Gerenciamento de Linhas
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button variant="contained" color="primary" onClick={handleLineModal}>
          Nova Linha
        </Button>
      </Box>

      <LineTable lines={lines} />
    </Container>
  );
};
