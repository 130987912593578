import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";
import { StyledButton } from "../../Buttons/StyledButton";

export const CourseTable = ({ courses }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleVisualize = (data) => {
    openModal("course-modal", "consult", data);
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm("Deseja realmente excluir este curso?");

    if (!confirmation) {
      return;
    }

    try {
      await api.delete(`/admin/course`, { data: { id } });
      window.location.reload();
      showFlashMessage("Exclusão bem-sucedida!", "success");
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="flex items-center justify-center">
        <div className="m-4">
          <p>Curso: {data?.name}</p>
          <p>Período:{data?.period?.name}</p>
          <p>Instituição: {data?.CourseOnInstitute[0]?.institute?.name}</p>
          <p>Cidade: {data?.CourseOnInstitute[0]?.institute?.city}</p>
        </div>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Nome",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Instituição",
        selector: (row) => row?.CourseOnInstitute[0]?.institute?.name || "",
        sortable: true,
      },
      {
        name: "Período",
        selector: (row) => row?.period?.name || "",
        sortable: true,
      },
      {
        name: "Ações",
        cell: (row) => (
          <button onClick={() => handleDelete(row.id)}>Deletar</button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setData(courses);
  }, [courses]);

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        expandableRows
        expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        onRowClicked={handleRowClick}
        expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
};
