import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import api from "../../../../utils/api";
import React, { useEffect, useState } from "react";

export const CoursexlinePage = () => {
  const [profiles, setProfiles] = useState([]);
  const [lines, setLines] = useState([]);
  let indice = 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar perfis e linhas da API
        const response = await api.get("/admin/reports/coursexline");
        setProfiles(response?.data?.profiles);
        setLines(response?.data?.busLines);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  // console.log("CoursexlinePage", profiles);

  return (
    <div>
      <h1>Relatório</h1>
      {lines?.map((line, index) => (
        <div key={index}>
          <hr />
          <h2>Linha de Ônibus: {line.name}</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "50px", textAlign: "left" }}>
                  Id
                </TableCell>
                <TableCell style={{ textAlign: "left" }}>Nome</TableCell>
                <TableCell style={{ textAlign: "left" }}>Curso</TableCell>
                <TableCell style={{ textAlign: "left" }}>Turno</TableCell>
                <TableCell style={{ textAlign: "left" }}>Modalidade</TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody>
              {profiles?.map((profileData, profileIndex) => (
                <React.Fragment key={profileIndex}>
                  {profileData?.profiles?.map((profile, innerIndex) => {
                    if (line?.id === profile?.busLineId) {
                      return (
                        <TableRow key={profile.id}>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {indice + 1}
                          </TableCell>

                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.CourseOnInstitute?.course?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.CourseOnInstitute?.course?.period?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.modalidade}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null; // Retornar null para evitar warnings
                  })}
                  {profileData?.profiles?.map((profile, innerIndex) => {
                    if (line?.id === profile?.busLineId) {
                      return (
                        <TableRow key={profile.id}>
                          <TableCell
                            style={{ width: "50px", textAlign: "left" }}
                          >
                            {indice + 1}
                          </TableCell>

                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.CourseOnInstitute?.course?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.CourseOnInstitute?.course?.period?.name}
                          </TableCell>
                          <TableCell style={{ textAlign: "left" }}>
                            {profile?.modalidade}
                          </TableCell>
                        </TableRow>
                      );
                    }
                    return null; // Retornar null para evitar warnings
                  })}
                </React.Fragment>
              ))}
            </TableBody> */}
            <TableBody>
              {profiles?.map((profile, profileIndex) => {
                return (
                  <TableRow key={profile.id}>
                    <TableCell style={{ width: "50px", textAlign: "left" }}>
                      {profileIndex + 1}
                    </TableCell>{" "}
                    <TableCell style={{ textAlign: "left" }}>
                      {profile?.name}
                    </TableCell>{" "}
                    <TableCell style={{ textAlign: "left" }}>
                      {profile?.CourseOnInstitute?.course?.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {profile?.CourseOnInstitute?.course?.period?.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {profile?.modalidade}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <hr />
        </div>
      ))}
    </div>
  );
};
