import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import { ChatHeader } from "../../components/ChatHeader";
import { SectorSelect } from "../../components/Inputs/SectorSelect";
import { useNavigate } from "react-router-dom";

export const Ouvidoria = () => {
  const { userId } = useAuth();
  const navigate = useNavigate();

  const [sector, setSector] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensChatSelecionado, setMensagensChatSelecionado] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChatSelection = (chat) => {
    setSelectedChat(chat);
    const chatMensagens = mensagens.filter((msg) => msg.chatId === chat.id);
    setMensagensChatSelecionado(chatMensagens);
  };

  const updateChat = async (selectedChat) => {
    if (selectedChat?.id) {
      setLoading(true);
      try {
        const { data } = await api.get(`/messages/message/${selectedChat.id}`);
        setMensagensChatSelecionado(data.messages);
      } catch (error) {
        console.error("Erro ao atualizar chat", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedChat?.id) {
      updateChat(selectedChat);
    }
  }, [selectedChat]);

  const handleSendMessage = async () => {
    const payload = {
      content: messageInput,
      senderId: userId,
      chatMessageId: selectedChat?.id,
    };

    try {
      await api.post("/admin/messages/message", payload);
      updateChat(selectedChat);
      setMessageInput("");
    } catch (error) {
      console.error("Erro ao enviar mensagem", error);
    }
  };

  const handleSectorSelect = (sectorId) => {
    setSector({ sectorId });
  };

  const handleCreateChat = async () => {
    const payload = {
      senderId: userId,
      sectorId: sector?.sectorId,
      content: "",
    };

    try {
      await api.post("/admin/messages", payload);
      navigate("/ouvidoria/chats");
    } catch (error) {
      console.error("Erro ao criar chat", error);
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10} md={8}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <ChatHeader title="Ouvidoria" />

          {/* Seleção de Setor */}
          <Box mb={2}>
            <SectorSelect onSelect={handleSectorSelect} />
          </Box>

          {/* Botão Criar Chat */}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleCreateChat}
            disabled={!sector}
            sx={{ marginBottom: 2 }}
          >
            Criar Chat
          </Button>

          {/* Chat selecionado e mensagens */}
          {selectedChat ? (
            <Box mt={3}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Chat {selectedChat.title}
              </Typography>

              {loading ? (
                <CircularProgress />
              ) : (
                <List sx={{ maxHeight: 300, overflowY: "auto" }}>
                  {mensagensChatSelecionado.map((mensagem) => (
                    <ListItem
                      key={mensagem.id}
                      sx={{
                        backgroundColor:
                          mensagem.senderId === userId ? "#e1f5fe" : "#e0e0e0",
                        marginBottom: 1,
                        borderRadius: 1,
                        padding: 1,
                      }}
                    >
                      <ListItemText
                        primary={mensagem.content}
                        secondary={format(
                          new Date(mensagem.createdAt),
                          "dd/MM/yyyy HH:mm"
                        )}
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              {/* Input de Mensagem */}
              <Box mt={2} display="flex" alignItems="center">
                <TextField
                  label="Digite sua mensagem"
                  variant="outlined"
                  fullWidth
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}
                  sx={{ marginRight: 1 }}
                />
                <IconButton
                  color="primary"
                  onClick={handleSendMessage}
                  disabled={!messageInput.trim()}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", mt: 3 }}>
              Selecione um chat para visualizar as mensagens.
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
