import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const PeriodSelect = ({ periods, selectedPeriod, onChange }) => {
  if (!periods) {
    return <div>Carregando períodos...</div>;
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel>Selecione o Período</InputLabel>
        <Select
          id="period"
          name="period"
          value={selectedPeriod}
          onChange={onChange}
          variant="outlined"
        >
          <MenuItem value="">Selecione o Período</MenuItem>
          {periods?.map((period) => (
            <MenuItem key={period.id} value={period.id}>
              {period.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
