import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import api from "../../utils/api";
import { TextInput } from "../Inputs/TextInput";
import { BusLineSelect } from "../Inputs/BusLineSelect";
import { InstituteSelect } from "../Inputs/InstituteSelect";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useModal } from "../../hooks/useModal";
import { ModalidadeSelect } from "../Inputs/ModalidadeSelect";

export const ProfileForm = ({ newProfile = false }) => {
  const { userId } = useAuth();

  const [profile, setProfile] = useState({});
  const [mode, setMode] = useState("cadastro");
  const [busLines, setBusLines] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const [institutes, setInstitutes] = useState([]);
  const { closeModal } = useModal();
  function handleChange(e) {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit", profile);

    try {
      const response =
        mode === "update"
          ? await api.put("/admin/profile", profile)
          : await api.post("/admin/profile", profile);

      console.log("response", response);
      showFlashMessage("Cadastrado com sucesso!", "success");
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log("error", error);
      console.log("error", error?.response?.data?.error?.message);

      error?.response?.data?.error?.message
        ? showFlashMessage(error?.response?.data?.error?.message, "error")
        : showFlashMessage("Falha ao cadastrar!", "error");
    }
  };

  useEffect(() => {
    if (userId === 0) return;
    async function fetchData() {
      if (newProfile) {
        setProfile((prevState) => ({
          ...prevState,
          newProfile: newProfile,
        }));
      } else {
        try {
          const url = `/admin/profile/${userId}`;
          console.log("userId", userId, url);
          const response = await api.get(url);
          console.log("res", response);

          setProfile(response.data?.profiles[0]);
          setMode("update");
        } catch (error) {
          console.log("error", error);
        }
      }
    }

    fetchData();
  }, [userId, newProfile]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  console.log("profile2 ->", userId, profile);
  if (userId === 0) {
    return <div>Carregando...</div>; // ou qualquer indicador de carregamento desejado
  }
  return (
    <div className="flex items-center justify-center bg-transparent p-12 px-4 text-black sm:px-6 lg:px-8">
      <div className="max-w-md h-full w-full">
        <form className="mt-4 space-y-2" onSubmit={handleSubmit}>
          <div className="flex justify-center my-6">
            <h1 className="text-2xl">Cadastro de novo academico</h1>
          </div>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Nome
            </label>
            <TextInput
              placeholder="Nome Completo"
              value={profile?.name || ""}
              name="name"
              onChange={handleChange}
            />
          </div>
          <TextInput
            name="email"
            placeholder="E-mail"
            value={profile?.email || ""}
            type="email"
            required
            onChange={handleChange}
          />
          <TextInput
            name="cpf"
            value={profile?.cpf || ""}
            placeholder="CPF"
            onChange={handleChange}
          />
          <TextInput
            name="rg"
            value={profile?.rg || ""}
            placeholder="RG"
            onChange={handleChange}
          />
          <TextInput
            name="tituloEleitor"
            value={profile?.tituloEleitor || ""}
            placeholder="Título de Eleitor"
            onChange={handleChange}
          />
          <TextInput
            name="phone"
            value={profile?.phone || ""}
            placeholder="Telefone"
            onChange={handleChange}
          />
          <BusLineSelect
            busLines={busLines}
            selectedId={profile?.busLineId || ""}
            name="busLineId"
            onChange={handleChange}
          />
          <InstituteSelect
            name="instituteId"
            selectedId={profile?.instituteId || ""}
            onChange={handleChange}
            institutes={institutes}
          />
          <ModalidadeSelect
            selectedId={profile?.instituteId || ""}
            onChange={handleChange}
            name="modalidade"
            // institutes={institutes}
          />
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {mode === "update" ? "Atualizar" : "Cadastrar"}
          </button>
        </form>
      </div>
    </div>
  );
};
