import React from "react";

export function StyledLabel({ children, ...props }) {
  return (
    <div className="my-2 grid grid-cols-11 gap-2">
      <span className="col-span-5 text-gray-700 font-medium pr-2">
        {children}
      </span>
      <span className="col-span-1 text-gray-700 pr-2">:</span>
      <span className="col-span-5 text-gray-900">
        {props.value ? props.value : "Não informado"}
      </span>
    </div>
  );
}
