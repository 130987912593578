import React from "react";
import { TextField, MenuItem } from "@mui/material";

export const PaymentStatusSelect = ({ value, onChange }) => {
  return (
    <TextField
      select
      label="Status do Pagamento"
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
    >
      <MenuItem value="all">Todos</MenuItem>
      <MenuItem value="pending">Pendente</MenuItem>
      {/* <MenuItem value="noReceipt">Comprovante não enviado</MenuItem> */}
      <MenuItem value="paid">Pago</MenuItem>
    </TextField>
  );
};
