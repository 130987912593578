import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Collapse,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import { mapPaymentsData } from "../../../utils/mapPaymentsData";
import { PaymentsService } from "../../../services/dashboardService";

const monthMapping = {
  "2024-01": "Janeiro",
  "2024-02": "Fevereiro",
  "2024-03": "Março",
  "2024-04": "Abril",
  "2024-05": "Maio",
  "2024-06": "Junho",
  "2024-07": "Julho",
  "2024-08": "Agosto",
  "2024-09": "Setembro",
  "2024-10": "Outubro",
  "2024-11": "Novembro",
  "2024-12": "Dezembro",
};

const monthOrder = Object.keys(monthMapping);

// Componente auxiliar para os detalhes de pagamento
const PaymentDetails = ({ month, notConfirmedPayments }) => (
  <Collapse in={true}>
    <Box
      sx={{
        padding: 2,
        backgroundColor: "background.paper",
        border: "1px solid #ddd",
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {`Detalhes de ${monthMapping[month]}`}
      </Typography>
      {notConfirmedPayments.map((payment) => (
        <Typography key={payment.id} variant="body2" gutterBottom>
          Nome: {payment?.user?.Profile[0]?.name}
        </Typography>
      ))}
    </Box>
  </Collapse>
);

export const Dashboard = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [paymentsDetailsData, setPaymentsDetailsData] = useState([]);
  const [expandedMonth, setExpandedMonth] = useState(null);
  const [order, setOrder] = useState("asc");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Carregar preferência de ordenação do localStorage
    const savedOrder = localStorage.getItem("dashboard-order");
    if (savedOrder) setOrder(savedOrder);

    const fetchPayments = async () => {
      try {
        const dashboardData = await PaymentsService.getPaymentsData();
        const dashboardDetailsData =
          await PaymentsService.getPaymentsDetailsData();
        setPaymentsDetailsData(dashboardDetailsData);
        setPaymentsData(mapPaymentsData(dashboardData));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, []);

  // Alternar ordem de ordenação
  const toggleOrder = () => {
    const newOrder = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);
    localStorage.setItem("dashboard-order", newOrder); // Salvar preferência no localStorage
  };

  // Ordenar dados com base na preferência
  const sortedPaymentsDetails = [...paymentsDetailsData].sort((a, b) => {
    const aIndex = monthOrder.indexOf(a.month);
    const bIndex = monthOrder.indexOf(b.month);

    return order === "asc" ? aIndex - bIndex : bIndex - aIndex;
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={2}>
      {/* Botão de alternar ordem */}
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleOrder}
          size="small"
        >
          {order === "asc" ? "Ordem Decrescente" : "Ordem Crescente"}
        </Button>
      </Box>
      <Grid container spacing={2}>
        {sortedPaymentsDetails.map((data) => {
          const selectedMonth = paymentsData.find(
            (_payments) => _payments?.month === monthMapping[data?.month]
          );

          return (
            <React.Fragment key={data.month}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                  onClick={() => setExpandedMonth(data.month)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { boxShadow: 6 },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" align="center">
                      {monthMapping[data.month]}
                    </Typography>
                    <Typography variant="body2" align="center">
                      Total: {selectedMonth?.totalPayments || 0}
                    </Typography>
                    <Typography variant="body2" align="center">
                      Não confirmados:{" "}
                      {selectedMonth?.notConfirmedPayments || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {expandedMonth === data.month && (
                <Grid item xs={12}>
                  <PaymentDetails
                    month={data.month}
                    notConfirmedPayments={data.notConfirmedPayments}
                  />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};
