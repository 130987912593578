import React, { useState } from "react";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";

export const BusLineForm = ({ onSuccess }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envie a solicitação POST para a rota admin/bus/line
      const response = await api.post("/admin/bus/line", formData);

      // Lógica adicional após o sucesso, se necessário
      console.log("Cadastro realizado com sucesso:", response.data);

      // Limpar os campos após o sucesso
      clearForm();
      navigate("/manager/lines");
      onSuccess();
    } catch (error) {
      // Trate erros aqui, se necessário
      console.error("Erro ao cadastrar linha de ônibus:", error);
    }
  };

  const clearForm = () => {
    setFormData({
      name: "",
      description: "",
    });
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-4">
        Cadastro de Linha de Ônibus
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-600"
          >
            Nome da Linha
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-600"
          >
            Descrição
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
            rows="3"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Cadastrar
        </button>
      </form>
    </div>
  );
};
