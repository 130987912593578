import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Box,
} from "@mui/material";

import api from "../../../utils/api";

export const VotoProgressoPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/voto/progresso");
        setData(response.data);
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error" align="center">
          Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ padding: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Progresso de Votação
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DataCard title="Usuários" value={data.usuarios} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataCard title="Usuários Ativos" value={data.usuariosAtivos} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataCard title="Votaram" value={data.votaram} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DataCard title="Não Votaram" value={data.naoVotaram} />
        </Grid>
      </Grid>
    </Container>
  );
};

export const DataCard = ({ title, value }) => (
  <Paper elevation={3} sx={{ padding: 2, textAlign: "center" }}>
    <Typography variant="h6" color="primary">
      {title}
    </Typography>
    <Typography variant="h5" fontWeight="bold">
      {value}
    </Typography>
  </Paper>
);
