import React from "react";

export function TextInput({ icon, ...props }) {
  return (
    <div className="relative">
      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        {icon}
      </div>
      <input
        class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500  "
        placeholder="Search"
        {...props}
      />
    </div>
  );
}
