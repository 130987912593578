import React, { useEffect, useState } from "react";
import { Preview } from "react-html2pdf";
import useAuth from "../../hooks/useAuth";
import api from "../../utils/api";
import html2pdf from "html2pdf.js";

const processImages = async (imagesArray) => {
  if (!imagesArray || imagesArray.length === 0) return [];
  const processedImages = await Promise.all(
    imagesArray.map(async (imageObj) => {
      const imgURL = imageObj.image;
      const image = await fetch(imgURL);
      const imageBlob = await image.blob();
      const blobUrl = URL.createObjectURL(imageBlob);
      return { ...imageObj, image: blobUrl };
    })
  );
  return processedImages;
};

export const Carteirinha = ({ children }) => {
  const { userId } = useAuth();

  const [profile, setProfile] = useState({});
  const [foto, setFoto] = useState("/images/avatar.png");
  const componentRef = React.useRef();

  const handleSavePdf = () => {
    console.log("click");
    const content = document.getElementById("pdf-content");
    const opt = {
      margin: 0,
      filename: "carteirinha.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(content).set(opt).save();
  };

  const handlePrint = () => {
    const content = componentRef.current;

    if (!content) {
      console.error("Content not found");
      return;
    }

    const contentClone = content.cloneNode(true);

    // Obtém os estilos do componente React
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          console.log("Stylesheet could not be loaded:", e);
          return "";
        }
      })
      .join("\n");

    // Cria um arquivo CSS com os estilos
    const styleBlob = new Blob([styles], { type: "text/css" });
    const styleUrl = URL.createObjectURL(styleBlob);

    // Abre uma nova janela para imprimir
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
     <html>
  <head>
    <title>Carteirinha</title>
    <link rel="stylesheet" type="text/css" href="${styleUrl}">
  </head>
  <body style="width: 85mm; height: 54mm; margin: 0; padding: 0;">
    <div class="bg-blue-600 border-black rounded m-2" style="font-size: 9px;width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: space-between;">
      <!-- Cabeçalho -->
      <div class="text-white text-xs" style="text-align: center; margin-bottom: 10px;">
        <h1 class="p-1">CARTEIRA DO ASSOCIADO</h1>
        <h2>ASSOCIAÇÃO DOS ACADEMICOS DE MALLET, rio claro do sul, dorizon e região</h2>
      </div>

      <!-- Conteúdo personalizado da carteirinha -->
      <div class="bg-white h-full flex" style="flex-grow: 1; align-items: center;">
        <img class="p-1" style="width: 30mm; height: 40mm" src="${foto}" alt="Foto do associado" />
        <div class="text-center">
          <h1 class="text-lg font-bold">${profile?.name}</h1>
          <p>Telefone:${profile?.phone}</p>
          <p>Curso: </p>
          <p>Instituição</p>
          <p>Data de Expiração: 31/12/2024</p>
        </div>
      </div>

      <!-- Rodapé -->
      <div style="text-align: right; margin-top: 10px;">
        <p class="text-white p-2 bg-blue-600" style="font-size: 9px;">ASSOCIAÇÃO DOS ACADEMICOS DE MALLET, rio claro do sul, dorizon e região - ${new Date().getFullYear()}</p>
      </div>
    </div>
  </body>
</html>
    `);
    printWindow.document.close();

    // Libera a URL do blob após a janela ser fechada
    printWindow.onbeforeunload = () => URL.revokeObjectURL(styleUrl);
  };

  useEffect(() => {
    if (userId === 0) return;

    async function fetchData() {
      try {
        // const url = `/admin/profile/1`;
        const url = `/admin/profile/${userId}`;
        console.log("userId", userId, url);
        const response = await api.get(url);
        console.log("res", response);

        setProfile(response.data?.profiles[0]);
        if (response.data?.profiles[0]?.carteirinhaImage) {
          const processedImages = await processImages([
            {
              image: `${process.env.REACT_APP_API}uploads/foto/${response.data?.profiles[0]?.carteirinhaImage}`,
            },
          ]);

          setFoto(processedImages[0]?.image || "/images/avatar.png");
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchData();
  }, [userId]);

  return (
    <div>
      <div className="max-w-md mx-auto rounded shadow-lg">
        <div
          className="flex flex-col justify-center"
          style={{ height: "54mm" }}
        >
          <div
            id="pdf-content"
            className="bg-blue-600 border-black rounded m-2"
            style={{
              fontSize: "9px",
              width: "85mm",
              // width: "85mm",
              // height: "54mm",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              className="text-white text-xs"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              <h1 className="p-1">CARTEIRA DO ASSOCIADO</h1>
              <h2 className="uppercase text-xs">
                ASSOCIAÇÃO DOS ACADEMICOS DE MALLET, rio claro do sul, dorizon e
                região
              </h2>
            </div>

            <div
              className="bg-white h-full flex"
              style={{ flexGrow: "1", alignItems: "center" }}
            >
              <img
                id="profile-image"
                className="p-1"
                style={{
                  width: "30mm",
                  height: "40mm",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                src={foto}
                alt="Foto do associado"
              />
              <div className="text-left">
                <h1 className="text-lg font-bold">{profile?.name}</h1>
                <p>Telefone: {profile?.phone}</p>
                <p>Curso:{profile?.CourseOnInstitute?.course?.name} </p>
                <p>Instituição:{profile?.institute?.name}</p>
                <p>Data de Expiração: 31/12/2024</p>
              </div>
            </div>

            <div style={{ textAlign: "right" }}>
              <p
                className="text-white p-2 h-full bg-blue-600"
                style={{ fontSize: "2mm" }}
              >
                <p>{""}</p>
                <p>{""}</p>

                {/* ASSOCIAÇÃO DOS ACADEMICOS DE MALLET, rio claro do sul, dorizon e
                região - {new Date().getFullYear()} */}
              </p>
            </div>
          </div>

          <Preview id="jsx-template">
            <div ref={componentRef}>{children}</div>
          </Preview>
          <div>
            {/* <button
              className="m-4 bg-blue-600 text-white px-4 py-2 rounded ml-2"
              onClick={handlePrint}
            >
              Visualizar
            </button> */}
            <button
              className="m-4 bg-blue-600 text-white px-4 py-2 rounded ml-2"
              onClick={handleSavePdf}
            >
              Baixar em PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
