import React from "react";
import { Modal } from "../Modal";
import { ProfileForm } from "../../Forms/ProfileForm";
import { ProfileVisualize } from "../../Visualize/ProfileVisualize";

export const ProfileModal = ({
  isOpen,
  onClose,
  operation = "register",
  data,
}) => {
  if (!isOpen) {
    return null;
  }

  let content;

  switch (operation) {
    case "register":
      content = <ProfileForm newProfile={true} />;
      break;
    case "consult":
      content = <ProfileVisualize newProfile={true} data={data} />;
      break;

    default:
      // content = null;
      content = <ProfileForm newProfile={true} />;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};
