import React from "react";
import { TextField, MenuItem } from "@mui/material";

export const StatusSelect = ({ value, onChange }) => {
  return (
    <TextField
      select
      label="Status do Usuário"
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
    >
      {/* <MenuItem value="all">Todos</MenuItem> */}
      <MenuItem value="active">Ativo</MenuItem>
      <MenuItem value="inactive">Inativo</MenuItem>
    </TextField>
  );
};
