import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { IconButton, Tooltip, TextField } from "@mui/material";
import { Delete, Visibility } from "@mui/icons-material";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useModal } from "../../hooks/useModal";
import api from "../../utils/api";

// Função que lida com a exclusão de um instituto
const handleDeleteInstitute = async (id, setData, showFlashMessage) => {
  try {
    await api.delete("/admin/institute", { data: { id } });
    showFlashMessage("Exclusão bem-sucedida!", "success");
    setData((prevData) => prevData.filter((item) => item.id !== id));
  } catch (error) {
    console.error("Erro ao excluir", error);
    showFlashMessage("Falha ao excluir!", "error");
  }
};

// Função que retorna os dados filtrados
const filterData = (data, searchQuery) => {
  if (!searchQuery) return data;
  return data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.id.toString().includes(searchQuery)
  );
};

// Função que calcula os dados para a página atual
const paginateData = (filteredData, currentPage, itemsPerPage) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return filteredData.slice(indexOfFirstItem, indexOfLastItem);
};

export const InstituteTable = ({ institutes }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState(institutes);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);
    setExpandedRows((prevExpandedRows) => {
      return isRowExpanded
        ? prevExpandedRows.filter((id) => id !== row.id)
        : [...prevExpandedRows, row.id];
    });
  };

  const handleVisualize = (data) => {
    openModal("institute-modal", {
      operation: "view",
      instituteId: data.id,
    });
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Nome",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Ações",
        cell: (row) => (
          <div>
            <Tooltip title="Deletar">
              <IconButton
                onClick={() =>
                  handleDeleteInstitute(row.id, setData, showFlashMessage)
                }
                sx={{ color: "red" }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    ],
    []
  );

  // Função de pesquisa e paginação
  const filteredData = useMemo(
    () => filterData(data, searchQuery),
    [data, searchQuery]
  );
  const currentData = useMemo(
    () => paginateData(filteredData, currentPage, itemsPerPage),
    [filteredData, currentPage, itemsPerPage]
  );

  return (
    <div>
      <TextField
        label="Pesquisar"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        margin="normal"
      />

      <DataTable
        columns={columns}
        data={currentData}
        onRowClicked={handleRowClick}
        // expandableRows
        // expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        // expandableRowsComponent={({ data }) => (
        //   <div className="flex items-center justify-center">
        //     <Tooltip title="Visualizar Instituto">
        //       <IconButton onClick={() => handleVisualize(data)}>
        //         <Visibility />
        //       </IconButton>
        //     </Tooltip>
        //   </div>
        // )}
        pagination
        paginationServer
        paginationTotalRows={filteredData.length}
        onChangePage={setCurrentPage}
        paginationPerPage={itemsPerPage}
        onChangeRowsPerPage={setItemsPerPage}
      />
    </div>
  );
};
