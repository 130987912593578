// UserSelect.js
import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const UserSelect = ({ onSelect }) => {
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    api
      .get("/admin/profile")
      .then((response) => {
        const sortedProfiles = response.data.profiles.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setProfiles(sortedProfiles);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      <Autocomplete
        value={selectedProfile}
        onChange={(event, newValue) => {
          console.log(event);
          setSelectedProfile(newValue);
          onSelect(newValue ? newValue.userId : null);
        }}
        options={profiles}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione um usuário"
            className="bg-white rounded"
          />
        )}
      />
    </div>
  );
};
