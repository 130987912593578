// UserSelect.js
import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const SectorSelect = ({ onSelect }) => {
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);
  useEffect(() => {
    api
      .get("/admin/sector")
      .then((response) => {
        console.log("response", response);
        setSectors(response?.data?.sectors);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, []);

  return (
    <div>
      <Autocomplete
        value={selectedSector}
        onChange={(event, newValue) => {
          //   console.log(event);
          //   console.log(event?.target);
          //   console.log(newValue);
          setSelectedSector(newValue);
          onSelect(newValue ? newValue : null);
        }}
        options={sectors}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione um setor"
            className="bg-white rounded"
          />
        )}
      />
    </div>
  );
};
