import React from "react";

export const CourseSelect = ({ courses, selectedCourse, onChange }) => {
  return (
    <select
      id="courseId"
      name="courseId"
      value={selectedCourse}
      onChange={onChange}
      // className="mt-1 p-2 border border-gray-300 rounded-md w-full"

      className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
    >
      <option value="">Selecione o Curso</option>
      {courses?.length &&
        courses.map((course) => (
          <option key={course.id} value={course.id}>
            {course.name}
          </option>
        ))}
    </select>
  );
};
