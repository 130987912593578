import React, { useEffect, useState, useRef } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import api from "../../../utils/api";

export const AdminOuvidoria = () => {
  const { userId } = useAuth();
  const [selectedChat, setSelectedChat] = useState(null);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensChatSelecionado, setMensagensChatSelecionado] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const chatEndRef = useRef(null); // Para rolagem automática
  const updateChat = async (selectedChat) => {
    if (selectedChat?.id) {
      api
        .get(`/admin/messages/message/${selectedChat?.id}`)
        .then((response) => {
          console.log("response", response);
          setMensagensChatSelecionado(response?.data?.messages);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };
  useEffect(() => {
    api
      .get("/admin/messages")
      .then((response) => setMensagens(response?.data))
      .catch((error) => console.error("error", error));
  }, []);

  useEffect(() => {
    if (selectedChat?.id) {
      api
        .get(`/admin/messages/message/${selectedChat.id}`)
        .then((response) =>
          setMensagensChatSelecionado(response?.data?.messages)
        )
        .catch((error) => console.error(error));
    }
  }, [selectedChat]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [mensagensChatSelecionado]);

  const handleChatSelection = (chat) => {
    setSelectedChat(chat);
  };

  const handleSendMessage = () => {
    if (!messageInput.trim()) return;
    const payload = {
      content: messageInput,
      senderId: userId,
      chatMessageId: selectedChat?.id,
    };
    api
      .post("/admin/messages/message", payload)
      .then(() => {
        updateChat(selectedChat);
        setMessageInput("");
      })
      .catch((error) => console.error(error));
  };

  const handleArchive = (chatId) => {
    api
      .put(`/admin/messages/archive/${chatId}`)
      .then(() => {
        setMensagens(mensagens.filter((msg) => msg.id !== chatId));
        if (selectedChat?.id === chatId) {
          setSelectedChat(null);
          setMensagensChatSelecionado([]);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredMensagens = mensagens.filter(
    (mensagem) => mensagem.isArchived === (tabIndex === 1)
  );

  return (
    <Box className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
          >
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Chats Ativos" />
              <Tab label="Chats Arquivados" />
            </Tabs>
            <List>
              {filteredMensagens.map((mensagem) => (
                <div key={mensagem.id}>
                  <ListItem
                    button
                    selected={selectedChat?.id === mensagem.id}
                    onClick={() =>
                      handleChatSelection({
                        id: mensagem.id,
                        title: `Chat ${mensagem.id}`,
                      })
                    }
                  >
                    <ListItemText primary={`Chat ${mensagem.id}`} />
                    <IconButton
                      edge="end"
                      aria-label="archive"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleArchive(mensagem.id);
                      }}
                    >
                      <ArchiveIcon />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Paper
            elevation={3}
            sx={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
          >
            {selectedChat ? (
              <Box p={2}>
                <Typography variant="h6">{selectedChat.title}</Typography>
                <List>
                  {mensagensChatSelecionado.map((mensagem) => (
                    <ListItem
                      key={mensagem.id}
                      sx={{
                        backgroundColor:
                          mensagem.senderId === userId ? "#e1f5fe" : "#e0e0e0",
                        padding: 1,
                        borderRadius: 1,
                        alignSelf:
                          mensagem.senderId === userId
                            ? "flex-end"
                            : "flex-start",
                      }}
                    >
                      <ListItemText
                        primary={mensagem.content}
                        secondary={format(
                          new Date(mensagem.createdAt),
                          "dd/MM/yyyy HH:mm"
                        )}
                      />
                    </ListItem>
                  ))}
                  <div ref={chatEndRef} />
                </List>
                <Box mt={2} display="flex" alignItems="center">
                  <TextField
                    variant="outlined"
                    label="Digite sua mensagem"
                    fullWidth
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendMessage}
                    disabled={!messageInput.trim()}
                    sx={{ ml: 2 }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>
            ) : (
              <Typography variant="h6" sx={{ p: 2 }}>
                Selecione um chat
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
