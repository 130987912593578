import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import api from "../../utils/api";

export const InstituteForm = ({ data, onSuccess }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { showFlashMessage } = useFlashMessage();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (data) {
      setIsEditing(true);
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, value);
      });
    } else {
      setIsEditing(false);
      reset();
    }
  }, [data, setValue, reset]);

  const onSubmit = async (formData) => {
    try {
      const endpoint = "/admin/institute";

      const response = isEditing
        ? await api.put(endpoint, formData)
        : await api.post(endpoint, formData);

      showFlashMessage(response.data.message, "success");
      onSuccess();
    } catch (error) {
      console.error("Error:", error);
      showFlashMessage("Erro ao processar o formulário", "error");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-md mx-auto mt-6 p-6 bg-white rounded-md shadow-md"
    >
      <div className="mb-4">
        <label
          htmlFor="name"
          className="block text-gray-600 font-semibold mb-2"
        >
          Nome:
        </label>
        <input
          type="text"
          id="name"
          {...register("name", { required: "Campo obrigatório" })}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        {errors.name && <p className="text-red-500">{errors.name.message}</p>}
      </div>

      <div className="mb-4">
        <label
          htmlFor="city"
          className="block text-gray-600 font-semibold mb-2"
        >
          Cidade:
        </label>
        <input
          type="text"
          id="city"
          {...register("city", { required: "Campo obrigatório" })}
          className="w-full p-2 border border-gray-300 rounded-md"
        />
        {errors.city && <p className="text-red-500">{errors.city.message}</p>}
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
      >
        {isEditing ? "Editar" : "Cadastrar"}
      </button>
    </form>
  );
};
