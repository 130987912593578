import React from "react";
import { Button, Grid } from "@mui/material";

export const FilterActions = ({ onToggleExpand, onClearFilters, expanded }) => (
  <Grid container spacing={2}>
    <Grid item xs={6} sm="auto">
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={onToggleExpand}
        sx={{
          width: { xs: "100%", sm: "auto" },
          minWidth: "150px",
        }}
      >
        {expanded ? "Ocultar Filtros" : "Exibir Filtros"}
      </Button>
    </Grid>
    <Grid item xs={6} sm="auto">
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        onClick={onClearFilters}
        sx={{
          width: { xs: "100%", sm: "auto" },
          minWidth: "150px",
        }}
      >
        Limpar filtros
      </Button>
    </Grid>
  </Grid>
);
