import React, { useState } from "react";
import {
  Button,
  Box,
  Container,
  Typography,
  Grid,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { CadastroForm } from "./CadastroForm";
import { ImageUploadForm } from "./ImageUploadForm";

export const Cadastro = () => {
  const [operacao, setOperacao] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleOperacaoChange = (operation) => {
    setLoading(true);
    setTimeout(() => {
      setOperacao(operation);
      setLoading(false);
    }, 500); // Simula o tempo de carregamento
  };

  const ButtonAcao = ({ label, ...props }) => (
    <Button
      {...props}
      variant="contained"
      color="primary"
      fullWidth
      sx={{ py: 1.5 }}
    >
      {label}
    </Button>
  );

  const handleSnackbarClose = () => setSnackbarMessage("");

  return (
    <Container maxWidth="sm" sx={{ mt: 5 }}>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: 3,
        }}
      >
        <Box sx={{ width: "100%", mb: 4 }}>
          {operacao !== "" && (
            <ButtonAcao
              label={"Voltar"}
              onClick={() => handleOperacaoChange("")}
            />
          )}
        </Box>

        <Typography variant="h4" align="center" gutterBottom>
          {operacao === ""
            ? "Selecione a operação desejada"
            : `Envio de ${operacao}`}
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            {operacao === "cadastro" && <CadastroForm />}
            {operacao === "residencia" && (
              <ImageUploadForm
                header="Envie seu comprovante de residencia"
                folder="residencia"
              />
            )}
            {operacao === "cpfImage" && (
              <ImageUploadForm
                header="Envie uma imagem de seu CPF"
                folder="cpf"
              />
            )}
            {operacao === "rgImage" && (
              <ImageUploadForm
                header="Envie uma imagem de seu RG"
                folder="rg"
              />
            )}
            {operacao === "titeleitor" && (
              <ImageUploadForm
                header="Envie uma imagem de seu título de eleitor"
                folder="titeleitor"
              />
            )}
            {operacao === "foto" && (
              <ImageUploadForm header="Envie sua foto 3x4" folder="foto" />
            )}
            {operacao === "matricula" && (
              <ImageUploadForm
                header="Envie seu comprovante de matricula"
                folder="compMatricula"
              />
            )}

            {operacao === "" && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Cadastrar informações"}
                    onClick={() => handleOperacaoChange("cadastro")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar foto 3x4"}
                    onClick={() => handleOperacaoChange("foto")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar Comprovante de matricula"}
                    onClick={() => handleOperacaoChange("matricula")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar comprovante de residencia"}
                    onClick={() => handleOperacaoChange("residencia")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar imagem do CPF"}
                    onClick={() => handleOperacaoChange("cpfImage")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar imagem do RG"}
                    onClick={() => handleOperacaoChange("rgImage")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ButtonAcao
                    label={"Enviar imagem do Titulo de eleitor"}
                    onClick={() => handleOperacaoChange("titeleitor")}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}

        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Box>
    </Container>
  );
};
