import React, { useEffect, useMemo, useState, useCallback } from "react";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";
import {
  Button,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Paper } from "@mui/material";
import DataTable from "react-data-table-component";

export const LineTable = ({ lines }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState(lines);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const fetchLines = useCallback(async () => {
    try {
      const response = await api.get("/admin/lines");
      setData(response.data.lines);
    } catch (error) {
      console.error("Erro ao buscar as linhas", error);
    }
  }, []);
  useEffect(() => {
    console.log("lines", lines);
    setData(lines);
  }, lines);
  const handleVisualize = (line) => {
    openModal("line-modal", { operation: null, onSuccess: fetchLines });
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm(
      "Deseja realmente excluir este registro?"
    );
    if (!confirmation) return;

    try {
      await api.delete("/admin/bus/line", { data: { id } });
      showFlashMessage("Exclusão bem-sucedida!", "success");
      fetchLines();
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowClick = (row) => {
    setExpandedRows((prevExpandedRows) => {
      const isRowExpanded = prevExpandedRows.includes(row.id);
      return isRowExpanded
        ? prevExpandedRows?.filter((id) => id !== row.id)
        : [...prevExpandedRows, row.id];
    });
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        style: {
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        selector: (row) => row.id,
        sortable: true,
      },
      { name: "Name", selector: (row) => row.name, sortable: true },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <Box display="flex">
            <IconButton onClick={() => handleVisualize(row)} color="primary">
              <VisibilityIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.id)} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );
  console.log("data", data);
  const filteredData = lines?.length
    ? lines?.filter(
        (line) =>
          line.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          line.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];
  // const filteredData = data?.length ? data : [];
  console.log("filteredData", filteredData);
  console.log("searchQuery", searchQuery);
  console.log("lines", lines);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchLines();
  }, [fetchLines]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Gerenciamento de Linhas
      </Typography>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Pesquisar por nome ou descrição"
          value={searchQuery}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col) => (
                <TableCell key={col.name}>{col.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                  {columns.map((col) => (
                    <TableCell key={col.name}>
                      {col.cell ? col.cell(row) : row[col.selector(row)]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <DataTable
        columns={columns}
        data={filteredData}
        // expandableRows
        // expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        onRowClicked={handleRowClick}
        // expandableRowsComponent={ExpandedComponent}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        columns
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
