import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Delete, ToggleOff, ToggleOn } from "@mui/icons-material";

import api from "../../../utils/api";
import {
  Button,
  Chip,
  TextField,
  Grid,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  IconButton,
} from "@mui/material";

export const TodosUsuarios = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filters, setFilters] = useState({
    id: "",
    email: "",
    name: "",
    isAdmin: "",
    isApproved: "",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters, users]);

  const fetchUsers = async () => {
    try {
      const res = await api.get("/admin/voto/progresso");
      setUsers(res?.data?.todosUsuarios || []);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  const applyFilters = () => {
    const filtered = users.filter((user) => {
      const profile = user.Profile?.[0] || {};
      return (
        (filters.id ? user.id.toString().includes(filters.id) : true) &&
        (filters.email
          ? user.email.toLowerCase().includes(filters.email.toLowerCase())
          : true) &&
        (filters.name
          ? profile.name?.toLowerCase().includes(filters.name.toLowerCase()) ||
            filters.name === "undefined"
          : true) &&
        (filters.isAdmin
          ? user.isAdmin === (filters.isAdmin === "true")
          : true) &&
        (filters.isApproved
          ? profile.isApproved === (filters.isApproved === "true")
          : true)
      );
    });
    setFilteredUsers(filtered);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const updateUserStatus = (userId, isInactive) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId
          ? {
              ...user,
              Flags: [
                {
                  ...user.Flags?.[0],
                  inativeUser: isInactive,
                },
              ],
            }
          : user
      )
    );
  };

  const handleToggleUserStatus = async (userId) => {
    try {
      const endpoint = "/admin/flags/inative";
      const response = await api.put(endpoint);
      const updatedFlag = response.data.flags;

      // Atualizar o estado de `users` com base no retorno da API
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === updatedFlag.userId
            ? {
                ...user,
                Flags: [{ ...updatedFlag }],
              }
            : user
        )
      );
    } catch (error) {
      console.error("Erro ao atualizar status do usuário:", error);
    }
  };
  const openDeleteDialog = (userId) => {
    setSelectedUserId(userId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setSelectedUserId(null);
  };

  const handleDeleteUser = async () => {
    try {
      await api.delete(`/admin/user/${selectedUserId}`);
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== selectedUserId)
      );
      closeDeleteDialog();
    } catch (error) {
      console.error("Erro ao deletar usuário:", error);
    }
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Nome",
      selector: (row) => row.Profile?.[0]?.name || "Indefinido",
      sortable: true,
    },
    {
      name: "Admin",
      selector: (row) => (row.isAdmin ? "Sim" : "Não"),
      sortable: true,
    },
    {
      name: "Aprovado",
      selector: (row) => (row.Profile?.[0]?.isApproved ? "Sim" : "Não"),
      sortable: true,
    },
    {
      name: "Ativo",
      selector: (row) => {
        const flag = row?.Flags?.[0];
        if (!flag) return <Chip label="Cadastro incompleto" color="warning" />;
        return flag.inativeUser ? (
          <Chip label="Inativo" color="error" />
        ) : (
          <Chip label="Ativo" color="primary" />
        );
      },
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const getStatusValue = (row) => {
          const flag = row?.Flags?.[0];
          if (!flag) return 2; // "Cadastro incompleto"
          return flag.inativeUser ? 1 : 0; // "Inativo" -> 1, "Ativo" -> 0
        };
        return getStatusValue(rowA) - getStatusValue(rowB);
      },
    },
    {
      name: "Ações",
      cell: (row) => {
        const isInactive = row?.Flags?.[0]?.inativeUser;
        return (
          <div>
            <Tooltip
              title={isInactive ? "Ativar Usuário" : "Desativar Usuário"}
            >
              <IconButton
                color={isInactive ? "primary" : "error"}
                onClick={() => handleToggleUserStatus(row.id)}
              >
                {isInactive ? <ToggleOn /> : <ToggleOff />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Deletar Usuário">
              <IconButton
                color="error"
                onClick={() => openDeleteDialog(row.id)}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Box padding={3}>
      <h3>Lista de Usuários</h3>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="ID"
            variant="outlined"
            value={filters.id}
            onChange={(e) => handleFilterChange("id", e.target.value)}
            placeholder="Filtrar por ID"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={filters.email}
            onChange={(e) => handleFilterChange("email", e.target.value)}
            placeholder="Filtrar por email"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            value={filters.name}
            onChange={(e) => handleFilterChange("name", e.target.value)}
            placeholder="Filtrar por nome"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel>Admin</InputLabel>
            <Select
              value={filters.isAdmin}
              onChange={(e) => handleFilterChange("isAdmin", e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="true">Sim</MenuItem>
              <MenuItem value="false">Não</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel>Aprovado</InputLabel>
            <Select
              value={filters.isApproved}
              onChange={(e) => handleFilterChange("isApproved", e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="true">Sim</MenuItem>
              <MenuItem value="false">Não</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DataTable
        columns={columns}
        data={filteredUsers}
        pagination
        highlightOnHover
        responsive
        noHeader
      />
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir este usuário? Esta ação não pode
            ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteUser} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
