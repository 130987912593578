import React, { useState } from "react";
import { Box, TextField, Button, Typography, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import api from "../../../utils/api";

export const CadastroChapa = () => {
  const [nomeChapa, setNomeChapa] = useState("");
  const [membros, setMembros] = useState([]);
  const [novoMembro, setNovoMembro] = useState({ nome: "", cargo: "" });
  const [editandoIndex, setEditandoIndex] = useState(null);

  const handleAdicionarMembro = () => {
    if (editandoIndex !== null) {
      const membrosAtualizados = [...membros];
      membrosAtualizados[editandoIndex] = novoMembro;
      setMembros(membrosAtualizados);
      setEditandoIndex(null);
    } else {
      setMembros([...membros, novoMembro]);
    }
    setNovoMembro({ nome: "", cargo: "" });
  };

  const handleEditarMembro = (index) => {
    setNovoMembro(membros[index]);
    setEditandoIndex(index);
  };

  const handleRemoverMembro = (index) => {
    setMembros(membros.filter((_, i) => i !== index));
  };

  const handleSalvarChapa = () => {
    const novaChapa = { nome: nomeChapa, membros };
    console.log("Chapa cadastrada:", novaChapa);
    const payload = { ...novaChapa };
    api.post("/admin/chapas", payload).then((response) => {
      console.log("response chapas ->");
      console.log(response);
      console.log("------------------");
    });
    setNomeChapa("");
    setMembros([]);
  };

  return (
    <Box sx={{ marginTop: "2rem" }}>
      <Typography variant="h5">Cadastro de Chapa</Typography>
      <TextField
        label="Nome da Chapa"
        fullWidth
        value={nomeChapa}
        onChange={(e) => setNomeChapa(e.target.value)}
        sx={{ mt: 2, mb: 2 }}
      />
      <Typography variant="h6">Adicionar Membro</Typography>
      <TextField
        label="Nome do Membro"
        fullWidth
        value={novoMembro.nome}
        onChange={(e) => setNovoMembro({ ...novoMembro, nome: e.target.value })}
        sx={{ mt: 2 }}
      />
      <TextField
        label="Cargo"
        fullWidth
        value={novoMembro.cargo}
        onChange={(e) =>
          setNovoMembro({ ...novoMembro, cargo: e.target.value })
        }
        sx={{ mt: 2 }}
      />
      <Button
        variant="contained"
        onClick={handleAdicionarMembro}
        sx={{ mt: 2 }}
      >
        {editandoIndex !== null ? "Atualizar Membro" : "Adicionar Membro"}
      </Button>

      <Box mt={4}>
        <Typography variant="h6">Membros da Chapa</Typography>
        {membros.map((membro, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>{`${membro.nome} - ${membro.cargo}`}</Typography>
            <Box>
              <IconButton onClick={() => handleEditarMembro(index)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => handleRemoverMembro(index)}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleSalvarChapa}>
          Salvar Chapa
        </Button>
      </Box>
    </Box>
  );
};
