import { useEffect, useState } from "react";
import { Typography, Container, Box } from "@mui/material";
import api from "../../utils/api";
import { EventsTable } from "../../components/Tables/EventsTable";

export function Home() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    api.get("/event").then((response) => {
      setEvents(response.data.events);
    });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ height: "100vh", p: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography
          variant="h3"
          component="h1"
          fontWeight="bold"
          color="white"
          gutterBottom
        >
          Bem-vindo à Plataforma Acadêmica do Município de Mallet
        </Typography>
        <Typography variant="body1" color="gray" mb={2}>
          Explore eventos acadêmicos e mantenha-se atualizado.
        </Typography>
      </Box>

      {events?.length > 0 && <EventsTable events={events} />}
    </Container>
  );
}
