import React from "react";
import { Box } from "@mui/material";

export const TabPanel = ({ children, active }) => (
  <Box
    role="tabpanel"
    hidden={!active}
    sx={{
      p: 2,
      backgroundColor: "background.paper",
      borderRadius: 1,
    }}
  >
    {children}
  </Box>
);
