// FlashMessageProvider.js
import React, { createContext, useContext, useState, useEffect } from "react";
import "./FlashMessage.css"; // Certifique-se de ter os estilos do Tailwind configurados

const FlashMessageContext = createContext();

export const FlashMessageProvider = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState(null);

  const showFlashMessage = (message, type = "success") => {
    setFlashMessage({ message, type });
    setTimeout(() => hideFlashMessage(), 3000); // Oculta a mensagem após 3 segundos
  };

  const hideFlashMessage = () => {
    setFlashMessage(null);
  };

  useEffect(() => {
    // Limpa a mensagem quando o componente é desmontado
    return () => {
      clearTimeout();
    };
  }, []);

  return (
    <FlashMessageContext.Provider
      value={{ showFlashMessage, hideFlashMessage }}
    >
      {children}
      {flashMessage && (
        <div
          className={`flash-message fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${flashMessage.type}`}
        >
          <p>{flashMessage.message}</p>
        </div>
      )}
    </FlashMessageContext.Provider>
  );
};

export const useFlashMessage = () => {
  const context = useContext(FlashMessageContext);
  if (!context) {
    throw new Error(
      "useFlashMessage deve ser usado dentro de FlashMessageProvider"
    );
  }
  return context;
};
