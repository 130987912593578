import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import api from "../../../utils/api";

export const ListagemChapas = () => {
  const [chapas, setChapas] = useState([]);

  const fetchChapas = async () => {
    api.get("/admin/chapas").then((response) => {
      if (response?.data?.chapas) {
        setChapas(response?.data?.chapas);
      }
    });
  };

  useEffect(() => {
    fetchChapas();
  }, []);

  return (
    <Box sx={{ maxWidth: "400px", margin: "auto", mt: 2 }}>
      <Typography variant="h5" align="center" gutterBottom>
        Listagem de Chapas
      </Typography>
      <Box
        sx={{
          maxHeight: "400px", // Altura máxima da lista
          overflowY: "auto", // Adiciona barra de rolagem vertical se necessário
          border: "1px solid #ddd", // Borda para destacar a área da lista
          borderRadius: "8px",
          padding: 2,
        }}
      >
        <List>
          {chapas.map((chapa) => (
            <Box key={chapa.id}>
              <ListItem>
                <ListItemText primary={`${chapa.nome}`} />
              </ListItem>
              {chapa.membros.map((membro, index) => (
                <ListItem key={index} sx={{ pl: 4 }}>
                  <ListItemText primary={`${membro.nome} - ${membro.cargo}`} />
                </ListItem>
              ))}
              <Divider />
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  );
};
