import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { DefaultContainer } from "../../../components/DefaultContainer";
import { ListagemChapas } from "./ListagemChapas";
import { CadastroChapa } from "./CadastroChapa";
import { PaginaVotacao } from "./PaginaVotacao";
import { EleicoesPage } from "./EleicoesPage";
import { VotoProgressoPage } from "./VotoProgressoPage";

export const CrudChapa = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <DefaultContainer>
      <Box sx={{ marginTop: "4rem" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="Chapas Tabs"
        >
          <Tab label="Listagem de Chapas" />
          <Tab label="Cadastro de Chapa" />
          <Tab label="Progresso" />
          {/* <Tab label="Votação" /> */}
          {/* <Tab label="Eleições" /> */}
        </Tabs>
      </Box>
      <Box mt={3}>
        {tabIndex === 0 && <ListagemChapas />}
        {tabIndex === 1 && <CadastroChapa />}
        {tabIndex === 2 && <VotoProgressoPage />}
        {/* {tabIndex === 2 && <PaginaVotacao />} */}
        {/* {tabIndex === 3 && <EleicoesPage />} */}
      </Box>
    </DefaultContainer>
  );
};
